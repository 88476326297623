import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { getAllPolls, updatePollStatus } from "../../../../../actions/poll";

const DialogDeletePoll = (props) => {
  const dispatch = useDispatch();

  const handleUpdatePollStatus = async () => {
    const res = await dispatch(
      updatePollStatus({
        idPoll: props.idPoll,
        status: "close",
      })
    );
    if (res) {
      dispatch(getAllPolls());
      props.onClose();
    }
  };

  return (
    <Dialog
      open={props.open}
      fullWidth={true}
      maxWidth={"sm"}
      onClose={props.onClose}
      aria-labelledby="alert-dialog-title-delete-group-name"
      aria-describedby="alert-dialog-description-delete-group-name"
    >
      <DialogTitle id="alert-dialog-title-delete-group-name">
        {"ลบ"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          คุณแน่ใจว่าจะปิดโหวต?{" "}
          <span style={{ color: "#f44336" }}>{props.displayClose}</span>
        </DialogContentText>
        <span style={{ color: "#f44336" }}>{props.idPollSelected}</span>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleUpdatePollStatus} color="primary">
          {" "}
          ปิดโหวต{" "}
        </Button>
        <Button onClick={props.onClose} color="primary">
          {" "}
          ยกเลิก{" "}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogDeletePoll;
