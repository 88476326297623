import React, { useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import { styled } from '@mui/material/styles';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import "./index.css";
import {
  Typography,
  Button,
  Divider,
  Grid,
  MenuItem,
  IconButton,
  Menu,
  Avatar,
  TablePagination,
  Chip,
  FormControlLabel,
  Switch,
  TableCell,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableBody,
  Autocomplete,
  TextField,
  Stack,
} from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { 
  updateActivityParticipantStatus, 
  getActivityParticipant, 
  updateRewardActivityRegister, 
  getActivity
} from "../../../../../actions/activity";
import { toast } from "sonner";
import SelectEmployees from "../../../shared/general/selectEmployees";
import { utils, writeFileXLSX } from "xlsx";
import { useOrganization } from "../../../../../quries/company";
import dayjs from "dayjs";

const StyledTableCell = styled(TableCell)({
  color: "#637381",
  backgroundColor: "#f4f6f8",
})

const StatusMenu = ({ idEmployees, handleChangeStatus }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  
  const handleClickMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClickMenu}
        >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id={`actions-menu-${idEmployees}`}
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseMenu}
        elevation={1}
      >
        <MenuItem onClick={() => handleChangeStatus({ idEmployees, status: 1 })}>
          {'เข้าร่วม'}
        </MenuItem>
        <MenuItem onClick={() => handleChangeStatus({ idEmployees, status: 0 })}>
          {'ไม่เข้าร่วม'}
        </MenuItem>
      </Menu>
    </>
  );
}

const EmployeeList = ({ 
  activity,
  activityParticipant,
  idActivity,
}) => {
  const dispatch = useDispatch();
  const organization = useOrganization();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [participantStatus, setParticipantStatus] = useState(true);
  const [companySelected, setCompanySelected] = useState(null);
  const [employeeSelected, setEmployeeSelected] = useState(null);
  const [departmentSelected, setDepartmentSelected] = useState({
    departmentName: "ทั้งหมด",
    idDepartment: 0,
  });
  const [sectionSelected, setSectionSelected] = useState({
    sectionName: "ทั้งหมด",
    idSection: 0,
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeEmployee = (value) => {
    setEmployeeSelected(value)
    setCompanySelected(null)
    setDepartmentSelected({
      departmentName: "ทั้งหมด",
      idDepartment: 0,
    })
    setSectionSelected({
      sectionName: "ทั้งหมด",
      idSection: 0,
    })
  }

  const resultFilter = activityParticipant
    ? activityParticipant.filter((item) => {
        if (employeeSelected) {
          return item.idEmployees === employeeSelected.idEmployees;
        }

        const filterDepartment = departmentSelected.idDepartment
          ? departmentSelected.idDepartment === item.idDepartment
          : true;

        const filterSection = sectionSelected.idSection
          ? sectionSelected.idSection === item.idSection
          : true;

        const filterRegister = participantStatus
          ? item.participantStatus
          : !item.participantStatus;

        return filterDepartment && filterSection && filterRegister;
      })
    : [];


  const handleChangeStatus = async ({ idEmployees, status }) => {
    const res = await dispatch(
      updateActivityParticipantStatus({
        idActivity,
        idEmployees,
        participantStatus: status,
      })
    )
    if(res) {
      dispatch(getActivityParticipant(idActivity))
    }
  };

  const handleGiveReward = async () => {
    const res = await dispatch(updateRewardActivityRegister(idActivity));
    if (res && res.status === 200) {
      toast.success("สำเร็จ")
      dispatch(getActivityParticipant(idActivity));
      dispatch(getActivity(idActivity));
    } else {
      toast.error("เกิดข้อผิดพลาด")
    }
  };

  const exportFile = useCallback(() => {
    let participant = [...activityParticipant]
    if (companySelected) {
      participant = activityParticipant.filter(emp => emp.idCompany === companySelected.idCompany)
    }
    
    const data = participant.map(emp => ({
      employeeID: emp.employeeID, 
      firstname_TH: emp.firstname_TH, 
      lastname_TH: emp.lastname_TH,
      companyName: emp.companyName,
      divisionName: emp.divisionName,
      departmentName: emp.departmentName,
      sectionName: emp.sectionName,
      positionName: emp.positionName,
      telephoneMobile: emp.telephoneMobile,
      participantStatus: emp.participantStatus ? "เข้าร่วม" : "ไม่เข้าร่วม"
    }))

    const ws = utils.json_to_sheet(data);
    utils.sheet_add_aoa(ws, [["รหัสพนักงาน", "ชื่อ", "นามสกุล", "บริษัท", "ฝ่าย", "ส่วน", "แผนก", "ตำแหน่ง", "เบอร์โทร", "สถานะ"]], { origin: "A1" });
    ws["!cols"] = [{ wch: 10 }, { wch: 25 }, { wch: 25 }, { wch: 30 }, { wch: 30 }, { wch: 30 }, { wch: 30 }, { wch: 30 }, { wch: 10 }];

    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws);
    writeFileXLSX(wb, `รายชื่อผู้ร่วมกิจกรรม ${activity.name}.xlsx`);
  }, [companySelected]);

  return (
    <>
      <Grid container alignItems={"center"} spacing={1} marginBottom={2}>
        <Grid item xs={12} sm={12} md={3}>
          <Autocomplete
            value={companySelected}
            options={organization.data}
            onChange={(event, newValue) => {
              setCompanySelected(newValue);
              setEmployeeSelected(null);
              setDepartmentSelected({departmentName: "ทั้งหมด",idDepartment: 0 });
              setSectionSelected({ sectionName: "ทั้งหมด", idSection: 0 });
            }}
            getOptionLabel={(option) => option.companyName}
            isOptionEqualToValue={(option, value) =>
              option.idCompany === value.idCompany
            }
            renderInput={(params) => <TextField {...params} label="บริษัท" />}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Autocomplete
            disableClearable
            value={departmentSelected}
            options={
              companySelected
                ? [
                    { departmentName: "ทั้งหมด", idDepartment: 0 },
                    ...companySelected.department,
                  ]
                : []
            }
            disabled={!companySelected || employeeSelected}
            onChange={(event, newValue) => setDepartmentSelected(newValue)}
            getOptionLabel={(option) => option.departmentName}
            isOptionEqualToValue={(option, value) =>
              option.idDepartment === value.idDepartment
            }
            renderInput={(params) => <TextField {...params} label="ส่วน" />}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Autocomplete
            disableClearable
            value={sectionSelected}
            options={
              companySelected
                ? [
                    { sectionName: "ทั้งหมด", idSection: 0 },
                    ...companySelected.section,
                  ]
                : []
            }
            disabled={!companySelected || employeeSelected}
            onChange={(event, newValue) => setSectionSelected(newValue)}
            getOptionLabel={(option) => option.sectionName}
            isOptionEqualToValue={(option, value) =>
              option.idSection === value.idSection
            }
            renderInput={(params) => <TextField {...params} label="แผนก" />}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <SelectEmployees
            value={employeeSelected}
            handleChange={handleChangeEmployee}
          />
        </Grid>
      </Grid>
      <Stack
        direction={{ xs: "column", sm: "row" }}
        alignItems={{ xs: "flex-start", sm: "center" }}
        justifyContent={"space-between"}
        gap={1}
        marginBottom={2}
      >
        <Typography color="textSecondary">
          {`รายการทั้งหมด (${resultFilter.length})`}
        </Typography>
        <div style={{ display: "flex", alignItems: "center", gap: "8px", flexWrap: "wrap" }}>
          {activity.status === "rewarded" && (
            <Typography variant="body2" color="textSecondary">
              {"ให้รางวัลแล้ว"}
            </Typography>
          )}
          <Button variant="contained" onClick={handleGiveReward}>
            {"ให้รางวัล"}
          </Button>
          <Button
            variant="contained"
            sx={{ minWidth: "fit-content" }}
            onClick={exportFile}
            startIcon={<FileDownloadIcon />}
          >
            {"ดาวน์โหลด"}
          </Button>
        </div>
      </Stack>
      <div style={{ display: "flex", alignItems: "center", gap: "8px", marginBottom: "16px" }}>
        <Typography color="textSecondary">{"เลือกสถานะ"}</Typography>
        <Chip
          clickable
          color="success"
          label={"เข้าร่วม"}
          onClick={() => setParticipantStatus(true)}
          variant={participantStatus ? "filled" : "outlined"}
        />
        <Chip
          clickable
          color="error"
          label={"ไม่เข้าร่วม"}
          onClick={() => setParticipantStatus(false)}
          variant={!participantStatus ? "filled" : "outlined"}
        />
      </div>
      <TableContainer component={Paper} elevation={2} >
        <Table sx={{ minWidth: 1000 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <StyledTableCell>{"ชื่อ"}</StyledTableCell>
              <StyledTableCell align="center">{"ฝ่าย"}</StyledTableCell>
              <StyledTableCell align="center">{"ส่วน"}</StyledTableCell>
              <StyledTableCell align="center">{"แผนก"}</StyledTableCell>
              <StyledTableCell align="center">{"ตำแหน่ง"}</StyledTableCell>
              <StyledTableCell align="center">{"เบอร์ติดต่อ"}</StyledTableCell>
              <StyledTableCell align="center">{"อีเมล"}</StyledTableCell>
              <StyledTableCell align="center">{"วันที่ได้รับเหรียญ"}</StyledTableCell>
              <StyledTableCell align="center">{"สถานะ"}</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {resultFilter
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((emp) => (
              <TableRow
                key={emp.idEmployees}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell>
                  <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                    <Avatar alt={"employee"} src={emp.imageURL} />
                    <Typography>
                      {`${emp.firstname_TH} ${emp.lastname_TH}`}
                    </Typography>
                  </div>
                </TableCell>
                <TableCell align="center">{emp.divisionName}</TableCell>
                <TableCell align="center">{emp.departmentName}</TableCell>
                <TableCell align="center">{emp.sectionName}</TableCell>
                <TableCell align="center">{emp.positionName}</TableCell>
                <TableCell align="center">{emp.telephoneMobile}</TableCell>
                <TableCell align="center">{emp.email}</TableCell>
                <TableCell align="center">
                  {dayjs(emp.rewardDate).format("DD-MM-YYYY HH:mm")}
                </TableCell>
                <TableCell>
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <Chip
                      label={emp.participantStatus ? "เข้าร่วม" : "ไม่เข้าร่วม"}
                      color={emp.participantStatus ? "success" : "error"}
                    />
                    <StatusMenu
                      idEmployees={emp.idEmployees}
                      handleChangeStatus={handleChangeStatus}
                    />
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={resultFilter.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};

export default EmployeeList;
