import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import FilterListIcon from "@mui/icons-material/FilterList";
import Drawer from "@mui/material/Drawer";
import { useNavigate } from "react-router";
import ButtonBlue from "../../shared/general/ButtonBlue";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import {
  Divider,
  Grid,
  Typography,
  IconButton,
  Snackbar,
  Alert
} from "@mui/material";
import HeaderPage from "../../shared/header/headerPage";
import MoraleQuestionIcon from "./assets/question.png";
import dayjs from "dayjs";
import { DialogDetail } from "../../manager/mission/missionAssign";
import { useDispatch, useSelector } from "react-redux";
import { getMissionByUserId, updateMission } from "../../../../actions/mission";
import DateFilter from "../../shared/dateFilter";
import MyMissionCard from "./component/my_mission_card";
const StyledRoot = styled("div")({
  display: "flex",
  flexDirection: "column",
  backgroundSize: "cover",
  margin: "0px auto",
  minWidth: 350,
  minHeight: "100vh",
  color: "#ffffff",
  backgroundColor: "#778899",
});


const StyledDivider = styled(Divider)({
  width: "100%",
  margin: "10px 0",
  backgroundColor: "#ffffff",
});




const WrapFilter = styled("div")({
  display: "flex",
  justifyContent: "flex-end",
  marginBottom: 16,
});

const FilterIconButton = styled(IconButton)({
  color: "#FFFFFF",
  backgroundColor: "#412020",
  "&:hover": {
    backgroundColor: "#5B3737",
  },
});



function Mymission() {
  const [openFilter, setOpenFilter] = useState(false);
  const [currentMission, setCurrentMission] = useState(null);
  const [dateFiiter, setDateFiiter] = useState(null);
  const navigate = useNavigate();
  const moment = require('moment');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [modeSnackbar, setModeSnackbar] = useState("info");
  const [displaySnackbar, setDisplaySnackbar] = useState("");
  const clearCurrentMission = () => {
    setCurrentMission(null);
  };

  const dispatch = useDispatch();
  const { result: missions} = useSelector((state) =>state.mission);
  const missionList  = missions?.filter(e => {
    if(e.idMissionStatus==2  && moment(e.submitDate).isBefore(e.missionDate)){
      return true;
    }else if(e.idMissionStatus == 1 && moment(new Date()).isBefore(e.missionDate)){
      return true;
    }else{
      return false;
    }
  });


  useEffect(() => {
    dispatch(getMissionByUserId());
  }, []);

  const toggleDrawer = (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setOpenFilter(!openFilter);
  };



  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const handleClickNavigateHistoryMyMission = () => {
    navigate("/my-mission-history");
  };
  return (
    <StyledRoot className={'page'}>
      <div style={{ display: "flex" ,justifyContent:"space-between"}}>
        <HeaderPage
          textLabel={"ภารกิจของฉัน"}
          icon={MoraleQuestionIcon}
          color={"#ffffff"}
        />
        <ButtonBlue
              variant="contained"
              startIcon={<AccessTimeIcon />}
              color="primary"
              onClick={handleClickNavigateHistoryMyMission}
              style={{ marginLeft: 16 }}
            >
              ประวัติภารกิจของฉัน
            </ButtonBlue>
      </div>
      <StyledDivider />
      <WrapFilter>
        <FilterIconButton aria-label="filter" onClick={toggleDrawer} size="large" >
            <FilterListIcon fontSize="large" />
          </FilterIconButton>
      </WrapFilter>
      <Drawer anchor={"right"} open={openFilter} onClose={toggleDrawer}>
        <DateFilter setFilterDate={setDateFiiter}/>
      </Drawer>
      <DialogDetail onClose={clearCurrentMission} mission={currentMission} />
      {missionList && missionList.length === 0 && (
        <Typography fontSize={64} textAlign={"center"} color={"white"}>
          ไม่มีภารกิจในขณะนี้
        </Typography>
      )}
      <Grid 
        container
        spacing={4}
        paddingLeft={"5%"} 
        paddingRight={"5%"}
      >
      {missionList &&
        missionList
          .filter((item) =>
            dateFiiter
              ? dayjs(item.missionDate).isSame(dateFiiter, "month")
              : item
          )
          .map((value, index) => {
            return (
              <MyMissionCard
              key={index}
              value={value}
              setCurrentMission={setCurrentMission}
              setSnackBarConfig={setOpenSnackbar} 
              setModeSnackbar={setModeSnackbar} 
              setDisplaySnackbar={setDisplaySnackbar}
              />
            );
          })}
      </Grid>
      <Snackbar
              open={openSnackbar}
              autoHideDuration={6000}
              onClose={handleCloseSnackbar}
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            >
              <Alert
                onClose={handleCloseSnackbar}
                sx={{ width: "100%" }}
                severity={modeSnackbar}
              >
          {displaySnackbar}
        </Alert>
      </Snackbar>
    </StyledRoot>
  );
}

export default Mymission;