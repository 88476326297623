import React from "react";
import ListMenu from "../../../pages/shared/listMenu";

import DashboardIcon from "@mui/icons-material/Dashboard";
import StoreIcon from "@mui/icons-material/Store";
import SettingsIcon from "@mui/icons-material/Settings";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import StarHalfOutlinedIcon from '@mui/icons-material/StarHalfOutlined';
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import EventNoteIcon from "@mui/icons-material/EventNote";
import MessageIcon from "@mui/icons-material/Message";
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import CampaignIcon from '@mui/icons-material/Campaign';
import ChatIcon from '@mui/icons-material/Chat';
import PollIcon from '@mui/icons-material/Poll';
import SummarizeIcon from '@mui/icons-material/Summarize';
import AssessmentIcon from '@mui/icons-material/Assessment';
import TollIcon from "@mui/icons-material/Toll";
import StorefrontIcon from '@mui/icons-material/Storefront';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import HistoryIcon from '@mui/icons-material/History';
import OfflineBoltIcon from '@mui/icons-material/OfflineBolt';
import SwapHorizontalCircleIcon from '@mui/icons-material/SwapHorizontalCircle';
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions';
import InsightsIcon from '@mui/icons-material/Insights';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';

const AdminMenu = () => {
  const dataListMenu = [
    {
      listItemIcon: <DashboardIcon />,
      listItemText: "มุมมองภาพรวม",
      listLink: "",
      listKey:"dashboard",
      collapse: [
        {
          listItemIcon: <InsightsIcon />,
          listItemText: "สุขภาพใจรายวัน",
          listLink: "/admin/dashboard/morale-daily/kpi",
          listKey:"moralekpi",
        },
        {
          listItemIcon: <EmojiEmotionsIcon />,
          listItemText: "ภาพรวมสุขภาพใจ",
          listLink: "/admin/dashboard/morale-kpi",
          listKey:"moralekpi",
        },
        {
          listItemIcon: <SwapHorizIcon />,
          listItemText: "รายการเหรียญ",
          listLink: "/admin/dashboard/transaction",
          listKey:"transaction",
        },
        {
          listItemIcon: <AccountBalanceIcon />,
          listItemText: "ภาพรวมงบประมาณ",
          listLink: "/admin/dashboard/budget-coin",
          listKey:"costreward",
        },
        {
          listItemIcon: <LocalAtmIcon />,
          listItemText: "ภาพรวมค่าใช้จ่าย",
          listLink: "/admin/dashboard/cost-reward",
          listKey:"costreward",
        },
        {
          listItemIcon: <CompareArrowsIcon />,
          listItemText: "ภาพรวมเหรียญหลัก",
          listLink: "/admin/dashboard/transfer",
          listKey:"transfer",
        },
        {
          listItemIcon: <StarHalfOutlinedIcon />,
          listItemText: "ภาพรวมเหรียญพิเศษ",
          listLink: "/admin/dashboard/special-coin",
          listKey:"specialcoin",
        },
      ],
    },
    {
      listItemIcon: <SettingsIcon />,
      listItemText: "การจัดการ",
      listLink: "",
      listKey:"manage",
      collapse: [
        {
          listItemIcon: <StoreIcon />,
          listItemText: "จัดการรางวัล",
          listLink: "/admin/manage/reward",
          listKey:"managereward",
        },
        {
          listItemIcon: <EventNoteIcon />,
          listItemText: "จัดการกิจกรรม",
          listLink: "/admin/manage/activity",
          listKey:"manageactivity",
        },
        {
          listItemIcon: <MessageIcon />,
          listItemText: "จัดการคำถามรายวัน",
          listLink: "/admin/manage/notification",
          listKey:"managenotification",
        },
        {
          listItemIcon: <HelpOutlineIcon />,
          listItemText: "จัดการคำถามสุขภาพใจ",
          listLink: "/admin/manage/morale-question",
          listKey:"managemoralequestion",
        },
        {
          listItemIcon: <PollIcon />,
          listItemText: "จัดการแบบสำรวจ",
          listLink: "/admin/manage/poll",
          listKey:"managepoll",
        },
        {
          listItemIcon: <AssessmentIcon />,
          listItemText: "จัดการแบบประเมิน 360",
          listLink: "/admin/manage/assessment360",
          listKey:"manageassessment360",
        },
        {
          listItemIcon: <OfflineBoltIcon  />,
          listItemText: "จัดการเหรียญรางวัล",
          listLink: "/admin/manage/coin",
          listKey:"managecoin",
        },
        {
          listItemIcon: <SwapHorizontalCircleIcon  />,
          listItemText: "โควต้าเหรียญรางวัล",
          listLink: "/admin/manage/coin-quota",
          listKey:"coin-quota",
        },
      ],
    },
    {
      listItemIcon: <SettingsIcon />,
      listItemText: "ข้อมูลระบบ",
      listLink: "/admin/setting",
      listKey:"setting",
    },
    {
      listItemIcon: <CardGiftcardIcon />,
      listItemText: "รายการแลกของรางวัล",
      listLink: "/admin/redeem-transaction",
      listKey:"redeemtransaction",
    },
    {
      listItemIcon: <ChatBubbleIcon />,
      listItemText: "การสื่อสาร",
      listLink: "",
      listKey:"communication",
      collapse: [
        {
          listItemIcon: <CampaignIcon />,
          listItemText: "ประกาศ",
          listLink: "/admin/announcement",
          listKey:"announcement",
        },
        {
          listItemIcon: <ChatIcon />,
          listItemText: "ข้อเสนอแนะ",
          listLink: "/admin/communication",
          listKey:"board",
        },
      ],
    },
    {
      listItemIcon: <SummarizeIcon />,
      listItemText: "รายงาน",
      listLink: "/admin/report",
      listKey: "report"
    },
    {
      listItemIcon: <AssessmentIcon />,
      listItemText: "ประเมิน 360",
      listLink: "/admin/assessment360",
      listKey:"assessment360",
    },
    {
      listItemIcon: <TollIcon />,
      listItemText: "ส่งเหรียญโดยบริษัท",
      listLink: "/admin/company-send-coin",
      listKey: "companysendcoin"
    },
    {
      listItemIcon: <StorefrontIcon />,
      listItemText: "รางวัล UNI",
      listLink: "",
      listKey: "uni-reward",
      collapse: [
        {
          listItemIcon: <ShoppingCartIcon />,
          listItemText: "ซื้อของรางวัล",
          listLink: "/admin/uni-reward",
          listKey: "uni-team-reward",
        },
        {
          listItemIcon: <HistoryIcon />,
          listItemText: "ประวัติการซื้อ",
          listLink: "/admin/uni-reward/order",
          listKey: "board",
        },
      ],
    },
  ];

  return (
    <ListMenu dataListMenu={dataListMenu} menuRole={"ผู้จัดการระบบ"} />
  );
};

export default AdminMenu;
