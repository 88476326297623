import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import InputField from "../../../shared/inputForm/inputField";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import {
  addQuestionTopic,
  getAllQuestionTopic,
  updateQuestionTopic,
} from "../../../../../actions/questionTopic";
import { toast } from "sonner";
import LoadingIcon from "../../../shared/general/loadingIcon";

const validationSchema = yup.object({
  questionTopic: yup.string().max(250).required(),
});

const DialogAddTopic = ({ open, handleClose, idCompany, selectTopic }) => {
  const dispatch = useDispatch();
  const {
    control,
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: { questionTopic: "" },
    resolver: yupResolver(validationSchema),
  });

  const handleAddTopic = async (values) => {
    let res = null
    if (selectTopic) {
      res = await dispatch(updateQuestionTopic(values));
    } else {
      res = await dispatch(addQuestionTopic({ ...values, idCompany }));
    }

    if (res && (res.status === 200 || res.status === 201)) {
      toast.success("สำเร็จ")
    } else {
      toast.error("เกิดข้อผิดพลาด")      
    }
    handleClose()
    dispatch(getAllQuestionTopic(idCompany))
  };

  useEffect(() => {
    if (selectTopic) {
      reset(selectTopic)
    }
  }, [])

  return (
    <Dialog 
      fullWidth
      open={open} 
      onClose={handleClose}
      PaperProps={{
        component: 'form',
        onSubmit: handleSubmit(handleAddTopic)
      }}
    >
      <DialogTitle>{selectTopic ? "Edit" : "Add"}</DialogTitle>
      <DialogContent>
        <InputField
          control={control}
          name={"questionTopic"}
          label="หัวข้อคำถาม"
          style={{ marginTop: "8px" }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button type="submit" variant="contained" disabled={isSubmitting}>
          {"ยืนยัน"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const QuestionTopic = ({ selectCompany }) => {
  const dispatch = useDispatch();
  const { result: questionTopic, isFetching } = useSelector((state) => state.questionTopic);
  const [open, setOpen] = useState(false);
  const [selectTopic, setSelectTopic] = useState(null);

  const handleOpenAdd = () => {
    setOpen(true);
  };

  const handleEdit = (value) => {
    setOpen(true);
    setSelectTopic(value);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectTopic(null);
  };

  useEffect(() => {
    if (selectCompany) {
      dispatch(getAllQuestionTopic(selectCompany.idCompany))
    }
  }, [selectCompany])
  
  if (isFetching) {
    return <LoadingIcon />
  }

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "16px",
        }}
      >
        <Typography variant="h6">หัวข้อคำถาม</Typography>
        <Button variant="contained" onClick={handleOpenAdd} startIcon={<AddIcon />}>
          เพิ่มหัวข้อคำถาม
        </Button>
      </div>
      <Paper>
        {questionTopic?.map((topic, index) => (
          <div key={topic.idQuestionTopic}>
            <div style={{ display: "flex", alignItems: "center", gap: "8px", padding: "8px" }}>
              <IconButton onClick={() => handleEdit(topic)}>
                <EditIcon />
              </IconButton>
              <Typography>{topic.questionTopic}</Typography>
            </div>
            {questionTopic.length - 1 !== index && (
              <Divider flexItem />
            )}
          </div>
        ))}
      </Paper>
      {open && (
        <DialogAddTopic
          open={open}  
          handleClose={handleClose}
          idCompany={selectCompany.idCompany}
          selectTopic={selectTopic}
        />
      )}
    </div>
  );
};

export default QuestionTopic;
