import React, { useEffect, useState } from "react";
import { styled } from '@mui/material/styles';
import {
  Divider,
  Typography,
  Paper,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Chip,
  Grid,
  Avatar,
  Container,
  Select,
  InputLabel,
  FormControl,
  Link,
} from "@mui/material";
import DialogDetailManageCoin from "./dialogRewardCoin";
import DialogDetailManageComment from "./dialogReply";

import HeaderPage from "../../shared/header/headerPage";
import iconHeader from "./assets/communication-svgrepo-com.svg";
import { MainCoin } from "../../shared/typeCoin";

//icon
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { useDispatch, useSelector } from "react-redux";
import { getAllCommunication, updateCommunication } from "../../../../actions/communication";
import dayjs from "dayjs";
import LoadingIcon from "../../shared/general/loadingIcon";
import { DatePicker } from "@mui/x-date-pickers";

const StyledRoot = styled('div')({
  display: "flex",
  flexDirection: "column",
  margin: "0px auto",
  minWidth: 400,
  backgroundColor: "#f5f5f5",
  minHeight: "100dvh",
})

const StyledDivider = styled(Divider)({
  margin: "10px 0",
})

const WrapHeader = styled('div')({
  display: "flex",
  alignItems: "center",
  ["@media only screen and (max-width:600px)"]: {
    flexDirection: "column",
  },
})

const TypeMenu = ({ idCommunication }) => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  
  const handleClickMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleChangeType = async (isAction) => {
    const res = await dispatch(
      updateCommunication({ idCommunication, isAction })
    )
    if(res) {
      dispatch(getAllCommunication())
      setAnchorEl(null);
    }
  };

  return (
    <>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClickMenu}
        >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id={`actions-menu`}
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseMenu}
        elevation={1}
      >
        <MenuItem onClick={() => handleChangeType(1)}>
          {'action'}
        </MenuItem>
        <MenuItem onClick={() => handleChangeType(0)}>
          {'inaction'}
        </MenuItem>
      </Menu>
    </>
  );
}

const StatusMenu = ({ idCommunication }) => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  
  const handleClickMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleChangeStatus = async (isSuccess) => {
    const res = await dispatch(
      updateCommunication({ idCommunication, isSuccess })
    )
    if(res) {
      dispatch(getAllCommunication())
      setAnchorEl(null);
    }
  };

  return (
    <>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClickMenu}
        >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id={`actions-menu`}
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseMenu}
        elevation={1}
      >
        <MenuItem onClick={() => handleChangeStatus(1)}>
          {"สำเร็จ"}
        </MenuItem>
        <MenuItem onClick={() => handleChangeStatus(0)}>
          {"ยกเลิก"}
        </MenuItem>
      </Menu>
    </>
  );
}

const Topic = ({
  idCommunication,
  detail,
  firstname_TH,
  lastname_TH,
  imageURL,
  createDate,
  reward,
  replyList,
  LikeList,
  isAction,
  isSuccess,
  idEmployees,
  file,
}) => {
  const [openDialogCoin, setOpenDialogCoin] = useState(false);
  const [openDialogComment, setOpenDialogComment] = useState(false);

  const handleCloseDialogCoin = () => {
    setOpenDialogCoin(false)
  }
  const handleOpenDialogCoin = () => {
    setOpenDialogCoin(true)
  }

  const handleCloseDialogComment = () => {
    setOpenDialogComment(false)
  }

  const handleOpenDialogComment = () => {
    setOpenDialogComment(true)
  }

  return (
    <>
      {openDialogCoin && (
        <DialogDetailManageCoin
          idCommunication={idCommunication}
          open={openDialogCoin}
          idEmployees={idEmployees}
          handleClose={handleCloseDialogCoin}
        />
      )}
      {openDialogComment && (
        <DialogDetailManageComment
          idCommunication={idCommunication}
          open={openDialogComment}
          handleClose={handleCloseDialogComment}
          replyList={replyList}
        />
      )}
      <Paper elevation={2} style={{ height: "100%", padding: "16px", borderRadius: "8px" }}>
        <div style={{ height: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
          <Typography fontSize={18}>{detail}</Typography>
          <div>
            <Divider style={{ marginBottom: "8px" }} />
            <div 
              style={{ 
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "16px" 
              }}
            >
              <div>
                <Chip
                  label={
                    isSuccess === 1
                      ? "success"
                      : isSuccess === 0
                      ? "cancel"
                      : "pending"
                  }
                  color={
                    isSuccess === 1
                      ? "success"
                      : isSuccess === 0
                      ? "error"
                      : "warning"
                  }
                />
                <StatusMenu idCommunication={idCommunication} />
                <Chip
                  label={
                    isAction === 1
                      ? "action"
                      : isAction === 0
                      ? "inaction"
                      : "pending"
                  }
                  color={
                    isAction === 1
                      ? "success"
                      : isAction === 0
                      ? "error"
                      : "warning"
                  }
                />
                <TypeMenu idCommunication={idCommunication} />
              </div>
              <div style={{ display: "flex", gap: "8px" }}>
                {reward && (
                  <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
                    <MainCoin width={30} />
                    <Typography variant="h6">{reward}</Typography>
                  </div>
                )}
                <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
                  <ThumbUpIcon color="primary" />
                  <Typography variant="h6">{LikeList.length}</Typography>
                </div>
              </div>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                <Avatar alt="employee" src={imageURL} />
                <div>
                  <Typography>
                    {`${firstname_TH} ${lastname_TH}`}
                  </Typography>
                  <Typography fontSize={14} color={"text.secondary"}>
                    {dayjs(createDate).format('DD/MM/YYYY HH:mm')}
                  </Typography>
                </div>
              </div>
              <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                {file && (
                  <IconButton LinkComponent={Link} target="_blank" href={file}>
                    <AttachFileIcon />
                  </IconButton>
                )}
                {!reward && (
                  <Chip
                    clickable
                    color="primary"
                    label={"มอบเหรียญ"}
                    onClick={handleOpenDialogCoin}
                  />
                )}
                <Chip
                  clickable
                  color="primary"
                  label={"การตอบกลับ"}
                  onClick={handleOpenDialogComment}
                />
              </div>
            </div>
          </div>
        </div>
      </Paper>
    </>
  );
};

export default function BoardAdmin() {
  const [type, setType] = useState('all')
  const [status, setStatus] = useState('all')
  const [filterDate, setFilterDate] = useState(dayjs());

  const dispatch = useDispatch()
  const { result: communication, isFetching } = useSelector((state) => state.communication)
  
  const handleChangeType = (event) => {
    setType(event.target.value);
  };

  const handleChangeStatus = (event) => {
    setStatus(event.target.value);
  };
  
  const rowFilter = () => {
    let rows = communication.filter((item) =>
      dayjs(item.createDate).isSame(filterDate, "month")
    );
    if (type !== "all") {
      rows = rows.filter((item) => item.type === type);
    }
    if (status !== "all") {
      rows = rows.filter((item) => item.isSuccess === status);
    }
    return rows;
  };
  
  useEffect(() => {
    dispatch(getAllCommunication())
  }, [])

  return (
    <StyledRoot className={'page'}>
      <WrapHeader>
        <HeaderPage textLabel={"บอร์ดพูดคุย"} icon={iconHeader} />
      </WrapHeader>
      <StyledDivider />
      <Container>
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} marginBottom={4}>
          <DatePicker
            value={filterDate}
            onChange={(newValue) => setFilterDate(newValue)}
            label={'เลือกเดือน'}
            views={['month']}
            minDate={dayjs().startOf('year')}
            maxDate={dayjs()}
          />
          <FormControl sx={{ minWidth: "160px" }}>
            <InputLabel>Type</InputLabel>
            <Select
              label="Type"
              value={type}
              onChange={handleChangeType}
            >
              <MenuItem value="all">All</MenuItem>
              <MenuItem value={null}>waiting</MenuItem>
              <MenuItem value={1}>action</MenuItem>
              <MenuItem value={0}>inaction</MenuItem>
            </Select>
          </FormControl>
          <FormControl sx={{ minWidth: "160px" }}>
            <InputLabel>Status</InputLabel>
            <Select
              label="Status"
              value={status}
              onChange={handleChangeStatus}
            >
              <MenuItem value="all">All</MenuItem>
              <MenuItem value={null}>waiting</MenuItem>
              <MenuItem value={1}>complete</MenuItem>
              <MenuItem value={0}>complete</MenuItem>
            </Select>
          </FormControl>
        </Stack>
        {isFetching ? (
          <LoadingIcon />
        ) : (
          <Grid container spacing={2}>
            {communication &&
              rowFilter().map((item) => (
                <Grid item xs={12} md={6}>
                  <Topic {...item} />
                </Grid>
              ))}
          </Grid>
        )}
      </Container>
    </StyledRoot>
  );
}
