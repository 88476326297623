import React, { useEffect, useState } from "react";
import { styled, IconButton, Typography } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDropzone } from "react-dropzone";

const Container = styled("div")({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: "2px",
  borderRadius: "2px",
  borderColor: '#eeeeee',
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
});

const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
};

const thumb = {
  // display: 'inline-flex',
  // boxSizing: 'border-box'
  // width: 160,
  height: 160,
  padding: 4,
};

const img = {
  display: 'block',
  width: 'auto',
  height: '100%',
  objectFit: "contain",
};

const UploadFile = ({ value, onChange, acceptFileType, error, multiple }) => {
  const [files, setFiles] = useState([]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: acceptFileType,
    onDrop: acceptedFiles => {
      setFiles(acceptedFiles.map(file => {
        onChange(file)
        return Object.assign(file, {
          preview: URL.createObjectURL(file)
        })
      }
    ))
    },
    multiple: multiple,
  });

  const handleRemove = (e) => {
    e.stopPropagation();
    onChange(null)
    setFiles([]);
  };

  const thumbs = files.map((file) => (
    <div style={thumb} key={file.name}>
      {file.type === "application/pdf" ? (
        <Typography fontWeight={500}>{file.name}</Typography>
      ) : (
        <img
          src={file.preview}
          style={img}
          alt="img"
          // Revoke data uri after image is loaded
          onLoad={() => {
            URL.revokeObjectURL(file.preview);
          }}
        />
      )}
    </div>
  ));

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => files.forEach(file => URL.revokeObjectURL(file.preview));
  }, [files]);

  useEffect(() => {
    if (value?.imageURL) {
      setFiles([{ ...value, preview: value.imageURL }])
    }
  }, [value]);

  return (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "8px" }}>
      <div className="container">
        <Container {...getRootProps()}>
          <input {...getInputProps({ onChange })} />
          {thumbs.length === 0 ? (
            <p>{"ลากและวางไฟล์ หรือคลิ๊กเพื่อเลือกไฟล์"}</p>
          ) : (
            <aside style={thumbsContainer}>
              {thumbs}
            </aside>
          )}
        </Container>
      </div>
      {thumbs.length !== 0 && (
        <IconButton sx={{ color: "#d32f2f" }} onClick={(e) => handleRemove(e)}>
          <DeleteIcon />
        </IconButton>
      )}
      {error && (
        <Typography style={{ color: "#d32f2f" }}>{error?.message}</Typography>
      )}
    </div>
  )
};

export default UploadFile