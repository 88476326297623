import { httpClient } from "./httpClient";

const getCompanySendCoin = (query) => {
  return httpClient.get("company-send-coin", { params: query });
};

const addCompanySendCoin = (formData) => {
  return httpClient.post("company-send-coin", formData);
};

const deleteCompanySendCoin = (id) => {
  return httpClient.delete(`company-send-coin/${id}`);
};

export default {
  getCompanySendCoin,
  addCompanySendCoin,
  deleteCompanySendCoin,
};
