import React,{useEffect} from "react";
import { styled } from "@mui/material/styles";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {  useNavigate,useLocation } from 'react-router-dom';
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from '@mui/icons-material/Favorite';
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded';
import { pink } from '@mui/material/colors';
import {  useDispatch } from "react-redux";
import { addFavoriteCoach,deleteFavoriteCoach } from "../../../../../actions/favoriteCoach";

import {
  Card,
  Grid,
  Stack,
  Avatar,
  Chip,
  Typography,
  Checkbox
} from "@mui/material";
import StarIcon from "@mui/icons-material/Star";

const StyledCard = styled(Card)({
  height:"100%",
  width:"100%",
  borderRadius: "16px",
  display:"flex",
  flexDirection:"column",
  justifyContent: "space-between" 

});

const StyledButton = styled(Grid)({
  background: "#7686920D",
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer", 
  transition: "background 0.3s ease", 
  padding: "20px",
  "&:hover": {
    background: "#e7edff",
    color: "#0147A3"
  },
});

const CardCoach = (props)=>{
    const { value, navigateTo,tagType } = props;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [favorite, setFavorite] = React.useState(value.isFavoriteCoach == 1);

    const handleChange = (event) => {
      if(event.target.checked){
        // add favorite
        dispatch(addFavoriteCoach({idCoach:value.idCoach}));
      }else{
        // delete favorite
        dispatch(deleteFavoriteCoach({idCoach:value.idCoach}))
      }
      setFavorite(event.target.checked);
    };


    const handleClick = (idCoach) => {
            navigate(`${navigateTo}#appointment`, { state: { idCoach } });
        };




    const handleClickProfile = (idCoach)=> {
      navigate(navigateTo,{state:{idCoach}})
    }
    return (
        <StyledCard>
            <Grid item>
            {
              tagType != "fix-coach" && (
                <div style={{display:'flex', justifyContent:"end"}}>
                  <Checkbox
                      checked={favorite}
                      onChange={handleChange}
                      inputProps={{ 'aria-label': 'controlled' }}
                      icon={<FavoriteBorderIcon/>}
                      checkedIcon={<FavoriteIcon/>}
                      sx={{
                        color: pink[800],
                        '&.Mui-checked': {
                          color: pink[600],
                        },
                      }}

                    />
              </div>
              )
            }
            {
              tagType == "fix-coach" && (
                <div style={{height:"16px"}}></div>
              )
            }
              <div style={{display:"flex",justifyContent:"center",paddingBottom:"16px"}}>
                <div style={{
                    display:"inline-flex", 
                    justifyContent:"center",
                    alignItems:"center",
                    padding:"8px",
                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                    borderRadius:"50%"
                    }}>
                  <Avatar
                    src={value.profileImageURL}
                    style={{ width: 130, height: 130 }} 
                    />
                </div>
              </div>

              <div   style={{display:"flex",flexDirection:"column",alignItems:"center",paddingRight:"24px" , paddingLeft:"24px",}}  >
                <Typography variant="h6" sx={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}>
                    {`${value.firstname_TH} ${value.lastname_TH}`}
                  </Typography>
                <Typography variant="subtitle2" color={"grey"} paddingBottom={"10px"}>
                      {`${value.positionName}`}
                </Typography>
                {
                  value.idJobGroup && (
                    <Chip label={value.jobGroupName} variant="filled" style={{backgroundColor:"#e7edff" ,color:"#0147A3",fontWeight:"600"}}/>

                  )
                }
                  <div style={{display:"flex",justifyContent:"center",alignItems:"center",margin:"20px 0px"}} >
                  <StarIcon fontSize="small" style={{ color: "#faaf00",marginRight:"5px"}} />
                  <Typography  variant="subtitle2">{`${value.rating??0}`}</Typography>
                </div>
              </div>
            </Grid>
            <Grid container >
              <StyledButton  container item xs={12} sm={6} md={6} lg={6} xl={6} onClick={()=>handleClick(value.idCoach)}>
                <CalendarMonthRoundedIcon sx={{color:"inherit",paddingRight:"5px",}}/>
                  <Typography variant="subtitle2" style={{ fontWeight: "500",color:"inherit" }} >
                    Book
                  </Typography>
                </StyledButton>
              <StyledButton  container item xs={12} sm={6} md={6} lg={6} xl={6} onClick={()=> handleClickProfile(value.idCoach)}>
              <InfoOutlinedIcon sx={{color:"inherit",paddingRight:"5px"}}/>
                <Typography variant="subtitle2" style={{ fontWeight: "500" }} color={"inherit"} justifyContent={"center"} alignItems={"center"}>
                    Profile
                  </Typography>    
                </StyledButton>
            </Grid>

        </StyledCard>
        
    )
}

export default CardCoach;



