import React, { Fragment, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import {
  Divider,
  Typography,
  Paper,
  Button,
  Chip,
  Card,
  Box,
  Grid,
  Dialog,
} from "@mui/material";

import {
  getAllAssessment,
} from "../../../../../actions/assessment";
import {
  getAllAppraisee,
} from "../../../../../actions/appraisee";
import { useNavigate } from "react-router-dom";
import ribbonPrize from "../assets/ribbon.png";
import IconButton from "@mui/material/IconButton";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CreateDuplicateAssessment from "../createAssessment/CreateDuplicateAssessment";
import LoadingIcon from "../../../shared/general/loadingIcon";

const AssessmentList = (value) => {
  // const history = useHistory()
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [alignment, setAlignment] = useState("all"); // Default to "กำลังดำเนินการ"
  const [selectedAssessment, setSelectedAssessment] = useState(null);
  const [assessmentQuestions, setAssessmentQuestions] = useState(
    selectedAssessment?.questionList || []
  );


  const [questionType, setQuestionType] = useState("");
  const [question, setQuestion] = useState("");

  const { result: assessmentList } = useSelector((state) => state.assessment);
  const { result: appraiseeList, isFetching } = useSelector(
    (state) => state.appraisee
  );

  const refreshAssessments = () => {
    dispatch(getAllAssessment());
    dispatch(getAllAppraisee()); // Re-fetch appraisee data
  };

  useEffect(() => {
    dispatch(getAllAssessment());
    dispatch(getAllAppraisee()); // Fetch appraisee data
  }, [dispatch]);

  const handleClickDescription = (id) => {
    navigate("/admin/manage/assessment360/" + id);
  };

  const handleClickUse = (id) => {
    navigate("/admin/manage/editassessment360/" + id);
  };

  // const isAssessmentActive = (assessmentId) => {
  //   const currentDate = new Date();

  //   if (!appraiseeList || !Array.isArray(appraiseeList)) {
  //     return false;
  //   }

  //   const appraisee = appraiseeList.find(
  //     (item) => item.idAssessment === assessmentId
  //   );

  //   if (appraisee && appraisee.startDate && appraisee.endDate) {
  //     const start = new Date(appraisee.startDate);
  //     const end = new Date(appraisee.endDate);

  //     return currentDate >= start && currentDate <= end;
  //   }
  //   return false;
  // };

  // useEffect(() => {
  //   if (Array.isArray(assessmentList) && Array.isArray(appraiseeList)) {
  //     const activeAssessmentsList = assessmentList.filter((assessment) => {
  //       const isActive = isAssessmentActive(assessment.idAssessment);

  //       // console.log(
  //       //   `Assessment ID: ${assessment.idAssessment}, Active: ${isActive}`
  //       // );
  //       return isActive;
  //     });

  //     // console.log(`Total active assessments: ${activeAssessmentsList.length}`);

  //     setActiveAssessments(activeAssessmentsList);
  //   }
  // }, [assessmentList, appraiseeList]);

  const isAssessmentActive = (assessmentId) => {
    const currentDate = new Date(); // Current date

    if (!appraiseeList || !Array.isArray(appraiseeList)) {
      return false;
    }
    const appraisee = appraiseeList.find(
      (item) => item.idAssessment === assessmentId
    );

    if (appraisee && appraisee.startDate && appraisee.endDate) {
      const start = new Date(appraisee.startDate);
      const end = new Date(appraisee.endDate);

      return currentDate >= start && currentDate <= end;
    }
    return false;
  };

  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  const [openModal, setOpenModal] = useState(false);
  const [currentAssessment, setCurrentAssessment] = useState(null);

  const handleOpenModal = (value) => {
    console.log(value.assessmentName);
    setOpenModal(true);
    setSelectedAssessment(value);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setCurrentAssessment(null);
  };

  const handleDelete = (index) => {
    const updatedQuestions = assessmentQuestions.filter((_, i) => i !== index);
    setAssessmentQuestions(updatedQuestions);
  };

  return (
    <container>
      <Typography>สถานะคำถาม</Typography>
      <ToggleButtonGroup
        value={alignment}
        exclusive
        onChange={handleAlignment}
        aria-label="text alignment"
        sx={{ marginTop: "2px" }}
      >
        <ToggleButton value="all">ทั้งหมด</ToggleButton>
        <ToggleButton value="inProgress">กำลังดำเนินการ</ToggleButton>
        <ToggleButton value="created">ไม่ใช้งาน</ToggleButton>
      </ToggleButtonGroup>

      {isFetching ? (
        <LoadingIcon />
      ) : (
        <Grid container spacing={2} sx={{ marginTop: "20px" }}>
          {assessmentList &&
            assessmentList
              .filter((value) => {
                if (alignment === "inProgress") {
                  return isAssessmentActive(value.idAssessment); // Show only active assessments
                } else if (alignment === "created") {
                  return !isAssessmentActive(value.idAssessment); // Show only inactive assessments
                } else if (alignment === "all") {
                  return true; // Show all assessments, no filter
                }
                return true; // Default case, show all
              })
              .map((value) => (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  key={value.idAssessment}
                  sx={{ padding: 2 }}
                >
                  <Card
                    sx={{
                      height: "100%",
                      borderRadius: "12px",
                      boxShadow: 3,
                      padding: 2,
                      transition: "transform 0.3s, box-shadow 0.3s",
                      "&:hover": {
                        transform: "scale(1.02)",
                        boxShadow: 6,
                      },
                    }}
                    // onClick={() => handleClickDescription(value.idAssessment)}
                  >
                    <Grid
                      container
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <img width={40} height={40} src={ribbonPrize} />
                      <Chip
                        sx={{
                          backgroundColor: isAssessmentActive(
                            value.idAssessment
                          )
                            ? "#ADACFB"
                            : "#EFEFEF",
                          color: "#000000",
                        }}
                        label={
                          isAssessmentActive(value.idAssessment)
                            ? "ดำเนินการ"
                            : "ไม่ใช้งาน"
                        }
                      />
                    </Grid>

                    <Card
                      sx={{
                        height: "65%",
                        borderRadius: "12px",
                        // boxShadow: 3,
                        justifyContent: "center",
                        marginTop: "8px",
                        backgroundColor:
                          value.assessmentType === "close"
                            ? "#FCCA63"
                            : "#E3C1BE",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          padding: "8px",
                        }}
                      >
                        <Chip
                          sx={{
                            fontSize: "13px",
                            backgroundColor: "#ffffff",
                          }}
                          label={
                            value.assessmentType === "close"
                              ? "คำถามปลายปิด"
                              : "คำถามปลายเปิด"
                          }
                        />

                        <IconButton
                          sx={{ color: "black" }}
                          onClick={() => {
                            console.log(
                              `ContentCopyIcon clicked on assessment: ${value.assessmentName} (ID: ${value.idAssessment})`
                            );
                            handleOpenModal(value);
                          }}
                        >
                          <ContentCopyIcon />
                        </IconButton>
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          padding: "8px",
                        }}
                      >
                        <div>
                          <Typography
                            variant="h6"
                            component="div"
                            fontWeight="bold"
                          >
                            {value.assessmentName}
                          </Typography>
                        </div>

                        <Box display="flex" alignItems="center">
                          <HelpCenterIcon sx={{ width: 20, height: 20 }} />
                          <Typography variant="body2" style={{ lineHeight: 1 }}>
                            จำนวนคำถาม : {value.questionList.length} ข้อ
                          </Typography>
                        </Box>
                        <Box display="flex" alignItems="center">
                          <CalendarMonthIcon sx={{ width: 20, height: 20 }} />
                          <Typography variant="body2">
                            {" "}
                            {dayjs(value.createDate).format("YYYY-MM-DD")}
                          </Typography>
                        </Box>
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-end",
                          padding: "8px",
                        }}
                      >
                        <Chip
                          sx={{
                            backgroundColor: "#ffffff",
                            color: "#000000",
                          }}
                          label={
                            " ใช้งานไปแล้ว " + value.numberOfUse + " ครั้ง"
                          }
                        />
                      </Box>
                    </Card>

                    <Divider
                      sx={{ width: "80%", marginTop: "15px", mx: "auto" }}
                    />

                    <Grid
                      container
                      sx={{
                        marginTop: "10px",
                        mx: "auto",
                        justifyContent: "center",
                        gap: "16px",
                      }}
                    >
                      <Grid>
                        <Chip
                          label="รายละเอียด"
                          variant="outlined"
                          sx={{
                            borderRadius: "5px",
                          }}
                          onClick={() =>
                            handleClickDescription(value.idAssessment)
                          }
                        />
                      </Grid>

                      <Grid>
                        <Chip
                          label="ใช้ชุดคำถาม"
                          variant="outlined"
                          sx={{
                            borderRadius: "5px",
                            backgroundColor: "#000000",
                            color: "white",
                            "&:hover": {
                              backgroundColor: "white", // Change background to white on hover
                              color: "#000000", // Change text color to black on hover (optional)
                            },
                          }}
                          onClick={() => handleClickUse(value.idAssessment)}
                        />
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>
              ))}
        </Grid>
      )}

      <CreateDuplicateAssessment
        open={openModal}
        handleClose={handleCloseModal}
        selectedAssessment={selectedAssessment}
        refreshAssessments={refreshAssessments}
      />

      {/* <StyledDialog open={openModal} onClose={handleCloseModal}>
        <Box sx={{ padding: "20px" }}>
         
          <Typography
            variant="h4"
            sx={{
              fontWeight: 600,
            }}
          >
            Question: {selectedAssessment?.assessmentName}
          </Typography>

 
          <Box sx={{ marginTop: "20px", padding: "0 60px" }}>
            {selectedAssessment?.questionList?.map((question, index) => (
              <Paper key={index} sx={{ marginBottom: "15px" }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "10px",
                  }}
                >
                  <Chip label={question.questionType} />

                  <Typography
                    variant="body1"
                    sx={{ flexGrow: 1, marginLeft: "20px" }}
                  >
                    {question.question}
                  </Typography>

                  <Grid item>
                    <IconButton>
                      <EditIcon />
                    </IconButton>
                  </Grid>

                  <Grid item>
                    <IconButton onClick={() => handleDelete(index)}>
                      <DeleteIcon />
                    </IconButton>
                  </Grid>
                </Box>
              </Paper>
            ))}
          </Box>
        </Box>
        <div>
          <Box
            display="flex"
            justifyContent="flex-start"
            mt={2}
            sx={{ marginLeft: "80px", marginTop: "-10px" }}
          >
            {showQuestionContainer && (
              <Box sx={{ mt: 2 }}>
                <TextField
                  select
                  label="ประเภทคำถาม"
                  value={questionType}
                  onChange={(e) => setQuestionType(e.target.value)}
                  fullWidth
                  sx={{ mb: 2 }}
                >
                  {questionTypes.map((type) => (
                    <MenuItem key={type} value={type}>
                      {type}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  label="คำถาม"
                  value={question}
                  onChange={(e) => setQuestion(e.target.value)}
                  fullWidth
                  multiline
                  rows={2}
                  sx={{ mb: 2 }}
                />
                <Button variant="contained" onClick={handleSaveQuestion}>
                  บันทึกคำถาม
                </Button>
              </Box>
            )}
          </Box>
        </div>

        <Button
          variant="outlined"
          // onClick={createQuestion}

          onClick={handleAddQuestionClick}
          color="primary"
          sx={{
            width: 150,
            color: "#EC5B6A",
            borderColor: "#EC5B6A",
            "&:hover": {
              borderColor: "#EC5B6A",
            },
            borderRadius: "10px",
          }}
        >
          Add Question
        </Button>
      </StyledDialog>  */}
    </container>
  );
};

export default AssessmentList;
