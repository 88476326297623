import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Typography,
  Divider,
  Avatar,
  Grid,
  Chip,
  Autocomplete,
  CardHeader,
  Stack,
  Alert,
  Snackbar,
  useMediaQuery,
  useTheme,
  Container,
  Link,
} from "@mui/material";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";
import Drawer from "@mui/material/Drawer";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FilterListIcon from "@mui/icons-material/FilterList";
import AddIcon from "@mui/icons-material/Add";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

import { MainCoin } from "../../shared/typeCoin";
import HeaderPage from "../../shared/header/headerPage";
import AutocompleteListUsers from "./../../shared/autocompleteListUsers";

import { useDispatch, useSelector } from "react-redux";
import { getAllEmployees } from "../../../../actions/user";
import {
  addMission,
  approveMission,
  getMissionByCreateId,
  updateMission,
} from "../../../../actions/mission";
import * as yup from 'yup';
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import InputField from "../../shared/inputForm/inputField";

const WrapMission = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: 16,
  margin: "auto",
});

const WrapFilter = styled("div")({
  display: "flex",
  alignItems: "center",
});

const StyledCard = styled(Card)({
  color: "#FFFFFF",
  backgroundColor: "#14213d",
  borderRadius: 16,
});

const StyledTitle = styled(Typography)({
  color: "#FFFFFF",
  fontSize: "24px",
});

const WrapDrawerFilter = styled("div")({
  marginTop: "64px",
  padding: 16,
  width: 300,
  "& .wrapBtnReset": {
    marginTop: 16,
    "& Button": {
      width: "100%",
      marginBottom: 8,
    },
  },
  "& .wrapListUsers": {
    marginTop: 16,
  },
});

const StyledDivider = styled(Divider)({
  width: "100%",
  margin: "10px 0",
});

const WrapTitleCard = styled("div")({
  width: "100%",
});

const WrapViewDetail = styled("div")({
  display: "flex",
  alignItems: "center",
  "& Button": {
    margin: 8,
  },
});

const WrapStatus = styled("div")({
  display: "flex",
  alignItems: "center",
  "& div": {
    margin: 8,
  },
});

const WrapCardButton = styled("div")({
  display: "flex",
  alignItems: "center",
  "& Button": {
    marginRight: 16,
  },
});

const StyledRoot = styled("div")({
  display: "flex",
  flexDirection: "column",
  backgroundSize: "cover",
  margin: "0px auto",
  padding: 16,
  marginTop: 60,
  minWidth: 350,
});

const DialogContentDetail = styled(DialogContent)({
  backgroundColor: "#ffe6e6",
});

const DialogActionsDetail = styled(DialogActions)({
  backgroundColor: "#ffe6e6",
});

const AddMissionButton = styled(Button)({
  marginRight: 8,
  fontSize: 20,
  backgroundColor: "#412020",
  "&:hover": {
    backgroundColor: "#5B3737",
  },
});

const FilterIconButton = styled(IconButton)({
  color: "#FFFFFF",
  backgroundColor: "#412020",
  "&:hover": {
    backgroundColor: "#5B3737",
  },
});

const colorStatus = ["warning", "warning", "secondary", "success", "error"];

export const DialogDetail = (props) => {
  const { onClose, mission } = props;
  const showDialog = Boolean(mission);

  return (
    <>
      {showDialog && (
        <Dialog open={showDialog} onClose={onClose} fullWidth>
          <DialogContentDetail>
            <Stack spacing={2}>
              <Card elevation={3}>
                <CardHeader title="Detail" />
                <CardContent>
                  <Typography variant="body2" color="text.secondary">
                    {mission.detail}
                  </Typography>
                </CardContent>
              </Card>
              <Card elevation={3}>
                <CardHeader title="Key learning" />
                <CardContent>
                  <Typography variant="body2" color="text.secondary">
                    {mission.keyLearning}
                  </Typography>
                </CardContent>
              </Card>
              <Card elevation={3}>
                <CardHeader title="File" />
                <CardContent>
                  <Button
                    component={Link}
                    href={mission.fileURL}
                    target="_blank"
                    disabled={!mission.fileURL}
                  >
                    View file
                  </Button>
                </CardContent>
              </Card>
            </Stack>
          </DialogContentDetail>
          <DialogActionsDetail>
            <Button variant="contained" color="error" onClick={onClose}>
              {"ปิด"}
            </Button>
          </DialogActionsDetail>
        </Dialog>
      )}
    </>
  );
};

const MissionAssign = () => {
  const theme = useTheme();
  const pageBreakpoint = useMediaQuery(theme.breakpoints.down("md"))

  const [openFilter, setOpenFilter] = useState(false);
  const [open, setOpen] = useState(false);
  const [dateMission, setDateMission] = useState(null);
  const [idUserMission, setIdUserMission] = useState([]);
  const [configSnackbar, setConfigSnackbar] = useState({
    open: false,
    type: "",
    content: "",
  })
  const [currentMission, setCurrentMission] = useState(null);

  const clearCurrentMission = () => {
    setCurrentMission(null);
  };

  const dispatch = useDispatch();
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: employees } = useSelector((state) => state.users);
  const { result: missionList } = useSelector((state) => state.mission);

  useEffect(() => {
    dispatch(getMissionByCreateId());
    dispatch(getAllEmployees("manager"));
  }, []);

  const toggleDrawer = (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setOpenFilter(!openFilter);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAddMission = async (values) => {
    const sumMissionReward = values.reward * values.person.length;
    if (sumMissionReward > userProfile.assignMissionBalance) {
      setConfigSnackbar({ open: true, type: "error", content: "จำนวนเหรียญไม่เพียงพอ" })
    } else {
      values.selectedPerson = values.selectedPerson.map(item => item.idEmployees)
      const res = await dispatch(addMission(values));
      if (res.status === 201) {
        dispatch(getMissionByCreateId());
        handleClose();
        setConfigSnackbar({ open: true, type: "success", content: "มอบหมายภารกิจสำเร็จ" })
      } else {
        setConfigSnackbar({ open: true, type: "error", content: "เกิดข้อผิดพลาด" })
      }
    }
  };

  const handleCancleMission = async (value) => {
    const res = await dispatch(updateMission(value.idMission, { ...value, idMissionStatus: 4 }));
    if (res) {
      dispatch(getMissionByCreateId());
    }
  };

  const handleApproveMission = async (value) => {
    const res = await dispatch(
      approveMission({
        idMission: value.idMission,
        idEmployees: value.idEmployees,
        reward: value.reward,
        idMissionStatus: 3,
      })
    );
    if (res) {
      dispatch(getMissionByCreateId());
    }
  };

  const validationSchema = yup.object().shape({
    missionTitle: yup.string().max(100).required("Name is required"),
    missionDetail: yup.string().max(200).required("Detail is required"),
    selectedPerson: yup.array().min(1).required("Person is required"),
    reward: yup
      .number()
      .required("Reward is require")
      .positive("must be positive")
      .integer(),
    missionDate: yup
      .date()
      .min(
        new Date(new Date().setHours(0, 0, 0, 0) + 86400000),
        "Mission date has to be after than current date"
      ),
  });

  const { control, handleSubmit } = useForm({
    defaultValues: {
      missionTitle: "",
      selectedPerson: [],
      missionDetail: "",
      missionDate: dayjs().add(1, 'day'),
      reward: 0,
    },
    resolver: yupResolver(validationSchema)
  })

  return (
    <StyledRoot className={'page'}>
      <div style={{ display: "flex" }}>
        <HeaderPage textLabel={"มอบหมายภารกิจ"} />
      </div>
      <StyledDivider />
      <Container maxWidth={"lg"}>
        {userProfile && missionList && employees && (
          <>
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              marginBottom={2}
            >
              <WrapFilter>
                <Typography fontSize={24} marginRight={2}>
                  {`จำนวนเหรียญที่เหลือ: ${userProfile.assignMissionBalance}`}
                </Typography>
                <MainCoin width={56} />
              </WrapFilter>
              <WrapFilter>
                <AddMissionButton
                  variant="contained"
                  startIcon={<AddIcon />}
                  onClick={handleClickOpen}
                >
                  New Mission
                </AddMissionButton>
                <FilterIconButton aria-label="filter" onClick={toggleDrawer}>
                  <FilterListIcon fontSize="large" />
                </FilterIconButton>
              </WrapFilter>
            </Stack>
            <WrapMission>
              <DialogDetail
                onClose={clearCurrentMission}
                mission={currentMission}
              />
              {missionList
                .filter((item) =>
                  idUserMission.length > 0
                    ? idUserMission.includes(item.idEmployees)
                    : item
                )
                .filter((item) => 
                  dateMission 
                    ? dayjs(item.missionDate).isSame(dateMission , "day")  
                    : item
                )
                .map((value) => {
                  return (
                    <StyledCard key={value.idMission} variant="outlined">
                      <CardContent>
                        <Grid container>
                          <Grid item xs={12} md={6}>
                            <Stack spacing={2}>
                              <WrapTitleCard>
                                <StyledTitle gutterBottom>
                                  <FormatQuoteIcon
                                    fontSize={"large"}
                                    style={{ transform: "scale(-1, 1)" }}
                                  />
                                    {value.missionTitle}
                                  <FormatQuoteIcon fontSize={"large"} />
                                </StyledTitle>
                                <Typography fontSize={"16px"} color="textSecondary">
                                  {dayjs(value.missionDate).format("D MMM YYYY ")}
                                </Typography>
                              </WrapTitleCard>
                              <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                                <img
                                  src={value.imageURL}
                                  alt="person"
                                  width={64}
                                  style={{ borderRadius: 45 }}
                                />
                                <Typography
                                  fontSize={18}
                                  color="#FFFFFF"
                                  style={{ wordWrap: "break-word" }}
                                >
                                  {`${value.firstname_TH} ${value.lastname_TH}`}
                                </Typography>
                              </div>
                              <div style={{ display: "flex", alignItems: "center", gap: 16 }}>
                                <Typography color="#FFFFFF">
                                  {value.reward}
                                </Typography>
                                <MainCoin width={40} />
                                <WrapStatus>
                                  <Typography color="#FFFFFF">
                                    Status :
                                  </Typography>
                                  <Chip
                                    label={value.missionStatus}
                                    color={colorStatus[value.idMissionStatus]}
                                    />
                                </WrapStatus>
                              </div>
                              <WrapCardButton>
                                <Button
                                  variant="contained"
                                  onClick={() => setCurrentMission(value)}
                                >
                                  View Detail
                                </Button>
                                {value.missionStatus === "submit" && (
                                  <Button
                                    variant="contained"
                                    color="success"
                                    onClick={() => handleApproveMission(value)}
                                  >
                                    Approve
                                  </Button>
                                )}
                                {(value.missionStatus === "in progress" ||
                                  value.missionStatus === "submit") && (
                                  <Button
                                    variant="contained"
                                    color="error"
                                    onClick={() => handleCancleMission(value)}
                                  >
                                    Cancel
                                  </Button>
                                )}
                              </WrapCardButton>
                            </Stack>
                          </Grid>
                          {pageBreakpoint ? (
                            <Divider
                              orientation={"horizontal"}
                              sx={{ width: "100%", borderColor: "#ffffff", marginTop: 1 }}
                              flexItem
                            />
                          ) : (
                            <Divider
                              orientation={"vertical"}
                              sx={{ borderColor: "#ffffff" }}
                              flexItem
                            />
                          )}
                          <Grid item xs={12} md={5}>
                            <Typography
                              padding={2}
                              fontSize={"16px"}
                              color="#FFFFFF"
                            >
                              {value.missionDetail}
                            </Typography>                
                          </Grid>
                        </Grid>
                      </CardContent>
                    </StyledCard>
                  );
                })}
            </WrapMission>
          </>
        )}
        {employees && (
          <Dialog open={open} onClose={handleClose}>
            <DialogTitle>New mission</DialogTitle>
            <form onSubmit={handleSubmit(handleAddMission)}>
              <DialogContent>
                <Grid container spacing={2} marginTop={0}>
                  <Grid item xs={12}>
                    <InputField 
                      control={control}
                      name="missionTitle"
                      label="Mission"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      control={control}
                      name="selectedPerson"
                      render={({ field, fieldState }) => (
                        <Autocomplete
                          {...field}
                          multiple
                          onChange={(event, value) => field.onChange(value)}
                          options={employees}
                          getOptionLabel={(option) =>
                            `${option.firstname_TH} ${option.lastname_TH}`
                          }
                          isOptionEqualToValue={(option, value) => option.idEmployees === value.idEmployees}
                          renderOption={(props, option) => (
                            <Box component="li" {...props} key={option.idEmployees}>
                              {`${option.firstname_TH} ${option.lastname_TH}`}
                            </Box>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              fullWidth
                              label="พนักงาน"
                              error={Boolean(fieldState.error)}
                              helperText={fieldState.error && fieldState.error.message}
                            />
                          )}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <InputField 
                      control={control}
                      name="missionDetail"
                      label="Detail"
                      multiline
                      rows={5}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Controller
                      control={control}
                      name="missionDate"
                      render={({ field, fieldState }) => (
                        <DatePicker
                          {...field}
                          label="Mission date"
                          minDate={dayjs().add(1, 'day')}
                          slotProps={{
                            textField: {
                              error: Boolean(fieldState.error),
                              helperText: fieldState?.error?.message,
                            },
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <InputField 
                      control={control}
                      name="reward"
                      label="Reward"
                      type="number"
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button
                  variant="contained"
                  color="error"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  color="success"
                >
                  Add
                </Button>
              </DialogActions>
            </form>
          </Dialog>
        )}
        <Drawer anchor={"right"} open={openFilter} onClose={toggleDrawer}>
          <AutocompleteListUsers 
            setIdUser={setIdUserMission} 
            setDate={setDateMission}
          />
        </Drawer>
        {configSnackbar.open && (
          <Snackbar
            open={configSnackbar.open}
            autoHideDuration={6000}
            onClose={() => setConfigSnackbar(prev => ({ ...prev, open: false }))}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
          >
            <Alert 
              severity={configSnackbar.type}
              onClose={() => setConfigSnackbar(prev => ({ ...prev, open: false }))} 
            >
              {configSnackbar.content}
            </Alert>
          </Snackbar>
        )}
      </Container>
    </StyledRoot>
  );
};

export default MissionAssign;
