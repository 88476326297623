import React, { Fragment, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import dayjs from "dayjs";
import {
  Typography,
  OutlinedInput,
  BottomNavigation,
  BottomNavigationAction,
  Stack,
  Select,
  MenuItem,
  Chip,
  Button,
  InputLabel,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Avatar from "@mui/material/Avatar";
import Alert from "@mui/material/Alert";
import SendIcon from "@mui/icons-material/Send";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoveToInboxIcon from "@mui/icons-material/MoveToInbox";
import OutboxIcon from "@mui/icons-material/Outbox";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { HeartCoin } from "../../shared/typeCoin";
import { useDispatch, useSelector } from "react-redux";
import {
  getHeartTransfer,
  updateHeartTransfer,
} from "../../../../actions/heartTransfer";
import { getAllEmployees } from "../../../../actions/user";
import SelectEmployees from "../../shared/general/selectEmployees";
import { toast } from "sonner";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import LoadingIcon from "../../shared/general/loadingIcon";

const StyledAccordionDetails = styled(AccordionDetails)(({ amount }) => {
  return amount < 0
    ? { flexDirection: "column" }
    : { flexDirection: "column-reverse" };
});

const StyledAccordionSummary = styled(AccordionSummary)(({ bgcolor }) => {
  return { backgroundColor: bgcolor };
});

const WrapCardHeader = styled("div")({
  display: "flex",
  alignItems: "center",
  gap: 8,
});

const WrapScore = styled("div")({
  display: "flex",
  alignItems: "flex-start",
  justifyContent: "center",
  paddingRight: 16,
});

const WrapChatFriend = styled("div")({
  display: "flex",
  alignItems: "center",
  maxWidth: 450,
});

const StyledChatFriend = styled(Alert)({
  backgroundColor: "#e3e3e3",
  color: "#282828",
});

const WrapChatMeReply = styled("div")({
  marginTop: 10,
  marginLeft: "auto",
  ["@media only screen and (min-width:768px)"]: {
    width: 350,
  },
  ["@media only screen and (min-width:992px)"]: {
    width: 500,
  },
  ["@media only screen and (min-width:1200px)"]: {},
});

const StyledChatMeSender = styled(Alert)({
  marginTop: 16,
  marginBottom: 5,
  marginLeft: "auto",
  maxWidth: 450,
});

const WrapAllScore = styled("div")({
  display: "flex",
  alignItems: "center",
  marginLeft: "auto",
});

const ReplyForm = ({ idHeartTransfer }) => {
  const dispatch = useDispatch();
  const schema = yup.object({
    reply: yup.string().max(60).nullable(),
  });

  const {
    control,
    watch,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: { reply: "" },
    resolver: yupResolver(schema),
  });

  const onSubmit = async (values) => {
    const res = await dispatch(
      updateHeartTransfer({
        idHeartTransfer,
        reply: values.reply,
      })
    );
    if (res && res.status === 201) {
      dispatch(getHeartTransfer());
      toast.success("เพิ่มข้อมูลสำเร็จ");
    } else {
      toast.success("เกิดข้อผิดพลาด");
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div style={{ marginBottom: 8 }}>
        <Controller
          control={control}
          name="reply"
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth
              inputProps={{ maxLength: 60 }}
              label="ข้อความ"
            />
          )}
        />
      </div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Typography color="textSecondary">
          {`(${watch("reply") ? watch("reply").length : 0}/60)`}
        </Typography>
        <Button
          type="submit"
          variant="contained"
          endIcon={<SendIcon />}
          disabled={isSubmitting}
        >
          {"ส่งข้อความ"}
        </Button>
      </div>
    </form>
  );
};

const HeartTransferItem = ({ item, mode }) => {
  return (
    <Accordion key={item.idHeartTransfer} sx={{ borderRadius: 2 }}>
      <StyledAccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        bgcolor={mode === "receiver" ? "#CAFFBF" : "#FFADAD"}
      >
        <WrapCardHeader>
          {mode === "receiver" ? (
            <Avatar alt={"receiver image"} src={item.sender_imageURL} />
          ) : (
            <Avatar alt={"sender image"} src={item.receiver_imageURL} />
          )}
          <div>
            <Typography>
              {mode === "receiver"
                ? `${item.sender_firstname_TH} ${item.sender_lastname_TH}`
                : `${item.receiver_firstname_TH} ${item.receiver_lastname_TH}`}
            </Typography>
            <Typography variant="body2">
              {dayjs(item.transferDate).format("D MMM YYYY")}
            </Typography>
          </div>
        </WrapCardHeader>
        <WrapAllScore>
          <WrapScore>
            <Typography style={{ fontSize: 36 }}>{item.value}</Typography>
            <HeartCoin width={20} />
          </WrapScore>
        </WrapAllScore>
      </StyledAccordionSummary>
      <StyledAccordionDetails amount={item.value}>
        <WrapChatFriend>
          {mode === "sender" ? (
            <StyledChatFriend icon={false} variant="filled">
              <Avatar
                style={{ marginRight: 16 }}
                alt={"receiver"}
                src={item.receiver_imageURL}
              />
              {item.reply ? item.reply : "ยังไม่มีการตอบกลับ"}
            </StyledChatFriend>
          ) : (
            <StyledChatFriend icon={false} variant="filled">
              <Avatar
                style={{ marginRight: 16 }}
                alt={"sender"}
                src={item.sender_imageURL}
              />
              {item.detail}
            </StyledChatFriend>
          )}
        </WrapChatFriend>
        <div>
          {mode === "sender" ? (
            <StyledChatMeSender icon={false} variant="filled" severity="info">
              <Avatar
                style={{ marginRight: 16 }}
                alt={"receiver"}
                src={item.receiver_imageURL}
              />
              {item.detail}
            </StyledChatMeSender>
          ) : (
            <Fragment>
              {item.reply ? (
                <StyledChatMeSender
                  icon={false}
                  variant="filled"
                  severity="info"
                >
                  <Avatar
                    style={{ marginRight: 16 }}
                    alt={"sender"}
                    src={item.sender_imageURL}
                  />
                  {item.reply}
                </StyledChatMeSender>
              ) : (
                <WrapChatMeReply>
                  <ReplyForm idHeartTransfer={item.idHeartTransfer} />
                </WrapChatMeReply>
              )}
            </Fragment>
          )}
          {mode === "receiver" && (
            <Typography
              style={{ textAlign: "right", fontSize: 14 }}
              variant="subtitle1"
              color="textSecondary"
            >
              เหตุผล: ข้อความแทนคำขอบคุณ
            </Typography>
          )}
        </div>
      </StyledAccordionDetails>
    </Accordion>
  );
};

const HeartTransferList = () => {
  const dispatch = useDispatch();
  const { result: heartTransfer, isFetching } = useSelector(
    (state) => state.heartTransfer
  );

  const [mode, setMode] = useState("receiver");
  const [dateFilter, setDateFilter] = useState(dayjs());
  const [filterEmployee, setFilterEmployee] = useState(null);
  const [filterScore, setFilterScore] = React.useState([1, 2, 3]);

  const handleChangeFilterScore = (event) => {
    const value = event.target.value;
    setFilterScore(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const filterData = heartTransfer
    ? heartTransfer.filter((item) => {
        let employee = true
        if (filterEmployee) {
          if (mode === "receiver") {
            employee = item.idSender === filterEmployee.idEmployees;
          } else {
            employee = item.idReceiver === filterEmployee.idEmployees;
          }
        }
        
        const score = filterScore.includes(item.value)
        const filterDate = dateFilter.isSame(item.transferDate, "month");

        return filterDate && score && employee;
      })
    : [];

  useEffect(() => {
    dispatch(getHeartTransfer());
    dispatch(getAllEmployees());
  }, []);

  return (
    <>
      {isFetching ? (
        <LoadingIcon />
      ) : (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: 20,
            }}
          >
            <BottomNavigation
              showLabels
              value={mode}
              onChange={(event, newValue) => {
                setMode(newValue);
              }}
            >
              <BottomNavigationAction
                label={"ได้รับ"}
                value="receiver"
                icon={<MoveToInboxIcon />}
              />
              <BottomNavigationAction
                label={"ส่งไป"}
                value="sender"
                icon={<OutboxIcon />}
              />
            </BottomNavigation>
          </div>
          <Grid
            container
            spacing={2}
            justifyContent="center"
            alignSelf={"center"}
            marginBottom={2}
          >
            <Grid item xs={12} sm={12} md={4}>
              <SelectEmployees
                value={filterEmployee}
                handleChange={setFilterEmployee}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <DatePicker
                sx={{ width: "100%" }}
                views={["month", "year"]}
                openTo="month"
                format="MMMM YYYY"
                minDate={dayjs().subtract(1, "year").startOf("year")}
                maxDate={dayjs()}
                value={dateFilter}
                onChange={(value) => setDateFilter(value)}
                label={"ค้นหาเดือน"}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormControl fullWidth>
                <InputLabel>{"จำนวนหัวใจ"}</InputLabel>
                <Select
                  multiple
                  label={"จำนวนหัวใจ"}
                  value={filterScore}
                  onChange={handleChangeFilterScore}
                  renderValue={(selected) => (
                    <Stack direction={"row"} flexWrap={"wrap"} gap={1}>
                      {selected.map((value, index) => (
                        <Chip
                          color="error"
                          size="small"
                          key={value}
                          label={value}
                          icon={<FavoriteIcon />}
                        />
                      ))}
                    </Stack>
                  )}
                >
                  <MenuItem key={"one"} value={1}>
                    <Checkbox checked={filterScore.indexOf(1) > -1} />
                    <div style={{ display: "flex" }}>
                      <HeartCoin width={25} />
                    </div>
                  </MenuItem>
                  <MenuItem key={"two"} value={2}>
                    <Checkbox checked={filterScore.indexOf(2) > -1} />
                    <div style={{ display: "flex" }}>
                      <HeartCoin width={25} />
                      <HeartCoin width={25} />
                    </div>
                  </MenuItem>
                  <MenuItem key={"three"} value={3}>
                    <Checkbox checked={filterScore.indexOf(3) > -1} />
                    <div style={{ display: "flex" }}>
                      <HeartCoin width={25} />
                      <HeartCoin width={25} />
                      <HeartCoin width={25} />
                    </div>
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          {filterData?.length > 0 ? (
            <Stack gap={1}>
              {filterData.map((item) => (
                <HeartTransferItem
                  key={item.idHeartTransfer}
                  item={item}
                  mode={mode}
                />
              ))}
            </Stack>
          ) : (
            <Typography variant="h5" textAlign={"center"}>
              {"ไม่มีข้อมูล"}
            </Typography>
          )}
        </>
      )}
    </>
  );
};

export default HeartTransferList;
