import React from "react";
import { styled } from "@mui/material/styles";
import dayjs from "dayjs";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { replace, useNavigate } from 'react-router-dom';
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Grid,
  Stack,
  Avatar,
  Chip,
  Typography,
  Box,
  Button
} from "@mui/material";
import StarIcon from "@mui/icons-material/Star";

const StyledCard = styled(Card)({
  height:"100%",
  width:"100%",
  borderRadius: "16px",
  display:"flex",
  flexDirection:"column",
  justifyContent: "space-between" 
});

const StyledButton = styled(Grid)({
  background: "#7686920D",
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer", 
  transition: "background 0.3s ease", 
  padding: "20px",
  "&:hover": {
    background: "#e7edff",
    color: "#0147A3"
  },
});

const CardWaitingCoach = (props)=>{
    const { value, tagType } = props;
    const navigate = useNavigate();
    const handleClick = (idCoach) => {
        navigate("coach-detail-record", {state: { idCoach }})
      };
    return (
        <StyledCard>
          <div>
            <div style={{display:"flex",justifyContent:"center",padding:"16px"}}>
              <div style={{
                  display:"inline-flex", 
                  justifyContent:"center",
                  alignItems:"center",
                  padding:"8px",
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                  borderRadius:"50%"
                  }}>
                <Avatar
                  src={value.profileImageURL}
                  style={{ width: 130, height: 130 }} 
                  />
              </div>
              </div>
              
              <div   style={{display:"flex",flexDirection:"column",alignItems:"center",paddingRight:"24px" , paddingLeft:"24px",}}  >
                <Typography variant="h6" sx={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}>
                    {`${value.firstname_TH} ${value.lastname_TH}`}
                  </Typography>
                <Typography variant="subtitle2" color={"grey"} paddingBottom={"10px"}>
                      {`${value.positionName}`}
                </Typography>
                {
                  value.idJobGroup && (
                    <Chip label={value.jobGroupName} variant="filled" style={{backgroundColor:"#e7edff" ,color:"#0147A3",fontWeight:"600"}}/>

                  )
                }
                  <div style={{display:"flex",justifyContent:"center",alignItems:"center",margin:"20px 0px"}} >
                  <StarIcon fontSize="small" style={{ color: "#faaf00",marginRight:"5px"}} />
                  <Typography  variant="subtitle2">{`${value.rating??0}`}</Typography>
                </div>
              </div>
          </div>
            <Grid container >
              <StyledButton  container item xs={12} onClick={()=> handleClick(value.idCoach)} >
                <InfoOutlinedIcon sx={{color:"inherit",paddingRight:"5px",}}/>
                  <Typography variant="subtitle2" style={{ fontWeight: "500",color:"inherit" }} >
                    รายละเอียด
                  </Typography>
                </StyledButton>
            </Grid>

        </StyledCard>
        
    )
}

export default CardWaitingCoach;



