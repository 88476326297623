import React from "react";
import dayjs from "dayjs";
import {
  Typography,
  Chip,
  Grid,
  Card,
  CardContent,
  Box,
  Alert,
} from "@mui/material";

import defaultImage from "./assets/placeholder.jpg"

const QuestionCard = ({ value, numberOfQuestion, handleClickAnswerQuestion }) => {
    const isOverdue = dayjs().isAfter(dayjs(value.endDate));

    return (
        <Grid item xs={12} sm={12} md={6} lg={4} xl={3} key={value.idMorale} 
          sx={{
          display: 'flex', 
          justifyContent: 'center', 
          alignItems: 'center' 
          }}
        >
          <Card sx={{ 
            width: '100%',
            height: '520px',
            borderRadius: '12px', 
            boxShadow: 3,
            display: 'flex',
            flexDirection: 'column',
          }} key={value.idMorale}>
            <CardContent>
            <Box
              sx={{
                backgroundColor: 'gray',
                height: '250px',
                borderRadius: '4px',
                marginBottom: '16px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}      
            >
              <img
              src={value.picture && value.picture.trim() !== '' ? value.picture : defaultImage} // Conditional rendering for the image
              alt="Morale"
              style={{
                width: '100%', 
                height: '100%', 
                objectFit: 'cover', 
              }}
              />
            </Box>
            <Box  sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width:'100%'}}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box sx={{height:'45px', width:'100%'}}>
                    <Typography variant="h6" component="div" fontWeight="bold" style={{ lineHeight: 1 }}>
                      แบบสอบถาม {value.moraleName} 
                    </Typography>
                  </Box>
                  <Box sx={{height:'30px'}}>
                  <Typography variant="body2" style={{ lineHeight: 1 }}>
                    รายละเอียด: {value.description}
                  </Typography>
                  </Box>
                  <Typography variant="body2">
                    จำนวนข้อ: {numberOfQuestion} ข้อ {`(${value.questionTopic.length} หัวข้อ)`}
                  </Typography>

                  <Typography variant="body2">
                    เริ่มต้น: {dayjs(value.startDate).format('DD MMM YYYY HH:MM')} 
                  </Typography>
                  <Typography variant="body2">
                    สิ้นสุด: {dayjs(value.endDate).format('DD MMM YYYY HH:MM')} 
                  </Typography>
              <Grid item xs={12} display={'flex'} alignItems={'center'} gap={2} marginTop={1} >
              <Box display="flex" height={20} flexDirection={"row"} width={'100%'}>
                      {value.isComplete
                        ? <Alert severity="success" sx={{ width: '100%', height:'50px', borderRadius:'10px', mt:'10px',}}>ตอบคำถามแล้ว</Alert>
                        : <Alert severity="warning" sx={{ width: '100%', height:'50px', borderRadius:'10px', mt:'10px'}}>ยังไม่ได้ตอบคำถาม</Alert>
                      }
                      {
                        !value.isComplete && (
                          <Chip 
                            label={dayjs().isAfter(dayjs(value.endDate)) ? 'เลยกำหนด' : 'ตอบคำถาม'}
                            // label={'ตอบคำถาม'}
                            color="primary" 
                            variant={dayjs().isAfter(dayjs(value.endDate)) ? 'outlined' : 'filled'} // Change variant based on date
                            sx={{
                              color: dayjs().isAfter(dayjs(value.endDate)) ? '#000' : '#fff', // Change text color based on state
                              backgroundColor: dayjs().isAfter(dayjs(value.endDate)) ? 'transparent' : '#177ef4', // Background color for 'ตอบคำถาม'
                              fontWeight: "500",
                              width: '150px', height:'50px', borderRadius:'10px', mt:'10px', borderWidth:2, ml:'10px'
                            }} 
                            onClick={() =>handleClickAnswerQuestion(value.idMorale)}
                            disabled={dayjs().isAfter(dayjs(value.endDate))}
                          /> 
                      )}
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </CardContent>
          </Card>
        </Grid>
    );
};

export default QuestionCard;