import React, { useEffect } from "react";
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Stack,
  TextField,
} from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import DeleteIcon from "@mui/icons-material/Delete";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import InputField from "../../../shared/inputForm/inputField";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { addPoll, getAllPolls } from "../../../../../actions/poll";
import { toast } from "sonner";
import { getAllEmployees } from "../../../../../actions/user";

const DialogAddPoll = ({ open, handleClose }) => {
  const dispatch = useDispatch();
  const { result: person } = useSelector((state) => state.users);

  const validationSchema = yup.object().shape({
    poll: yup.string().required("Poll name is required"),
    questions: yup
      .array()
      .of(
        yup.object().shape({
          question: yup
            .string()
            .trim()
            .min(1, "option is required")
            .required("Required"),
        })
      )
      .required("Must have option")
      .min(2, "Minimum of 2 option"),
    person: yup.array().min(1).required("Person is required"),
    endDate: yup
      .date()
      .min(
        new Date(new Date().setHours(0, 0, 0, 0) + 86400000),
        "End date has to be after than current date"
      ),
  });

  const { control, handleSubmit, setValue, watch } = useForm({
    defaultValues: {
      poll: "",
      questions: [{ question: "" }, { question: "" }],
      mode: "select",
      person: [],
      endDate: dayjs().add(1, "day"),
    },
    resolver: yupResolver(validationSchema),
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "questions",
  });

  const handleAddPoll = async (values) => {
    const voterId = values.person.map((item) => item.idEmployees);
    const res = await dispatch(
      addPoll({
        ...values,
        person: voterId,
        status: "open",
      })
    );
    if (res && res.status === 201) {
      dispatch(getAllPolls());
      toast.error("สำเร็จ");
    } else {
      toast.error("เกิดข้อผิดพลาด");
    }
  };


  useEffect(() => {
    dispatch(getAllEmployees());
  }, []);

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>New Poll</DialogTitle>
      <form onSubmit={handleSubmit(handleAddPoll)}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <InputField
                control={control}
                name={"poll"}
                fullWidth
                label="Poll"
              />
            </Grid>
            <Grid item xs={12}>
              <Stack spacing={1}>
                {fields.map((item, index) => (
                  <Stack key={item.id} direction={"row"}>
                    <Controller
                      control={control}
                      name={`questions.${index}.question`}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          fullWidth
                          label={`Option ${index + 1}`}
                          error={Boolean(fieldState.error)}
                          helperText={
                            fieldState.error && fieldState.error.message
                          }
                        />
                      )}
                    />
                    <IconButton
                      aria-label="delete"
                      disabled={fields.length === 2}
                      onClick={() => remove(index)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Stack>
                ))}
              </Stack>
              <Button
                disabled={fields.length > 5}
                onClick={() => append({ question: "" })}
              >
                Add Option
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="mode"
                render={({ field }) => (
                  <FormControl>
                    <FormLabel>Voter</FormLabel>
                    <RadioGroup
                      {...field}
                      row
                      onChange={(event) => {
                        if (event.target.value === "all") {
                          setValue("person", person);
                        } else {
                          setValue("person", []);
                        }
                        field.onChange(event.target.value);
                      }}
                    >
                      <FormControlLabel
                        value="all"
                        control={<Radio />}
                        label="All"
                      />
                      <FormControlLabel
                        value="select"
                        control={<Radio />}
                        label="Select"
                      />
                    </RadioGroup>
                  </FormControl>
                )}
              />
            </Grid>
            {watch("mode") === "all" ? null : (
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name="person"
                  render={({ field, fieldState }) => (
                    <Autocomplete
                      {...field}
                      multiple
                      options={person}
                      onChange={(event, value) => field.onChange(value)}
                      isOptionEqualToValue={(option, value) =>
                        option.idEmployees === value.idEmployees
                      }
                      getOptionLabel={(option) =>
                        `${option.firstname_TH} ${option.lastname_TH}`
                      }
                      renderOption={(props, option) => {
                        return (
                          <li {...props} key={option.idEmployees}>
                            {`${option.firstname_TH} ${option.lastname_TH}`}
                          </li>
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          style={{ width: "100%" }}
                          {...params}
                          label="พนักงาน"
                          error={Boolean(fieldState.error)}
                          helperText={
                            fieldState.error && fieldState.error.message
                          }
                        />
                      )}
                    />
                  )}
                />
              </Grid>
            )}
            <Grid item xs={6}>
              <Controller
                control={control}
                name="endDate"
                render={({ field, fieldState }) => (
                  <DatePicker
                    {...field}
                    label="End date"
                    minDate={dayjs().add(1, "day")}
                    slotProps={{
                      textField: {
                        error: Boolean(fieldState.error),
                        helperText: fieldState?.error?.message,
                      },
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="error" onClick={handleClose}>
            Cancel
          </Button>
          <Button type="submit" variant="contained" color="success">
            {"ยืนยัน"}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default DialogAddPoll;
