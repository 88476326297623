import React from "react";
import { useSelector } from "react-redux";
import Button from "@mui/material/Button";
import { Typography, Divider, Stack, Container, styled } from "@mui/material";
import HeaderPage from "../../shared/header/headerPage";
import BtnSendHeart from "./assets/btnSendHeart.jpg";
import SendHeartCoinHeader from "./assets/send.svg";
import { HeartCoin } from "../../shared/typeCoin";
import DialogSentHeart from "./dialogSentHeart";
import HeartTransferList from "./heartTransfer";

const StyledDivider = styled(Divider)({
  width: "100%",
  margin: "10px 0",
});

const WrapBtnSendHeart = styled("div")({
  display: "flex",
  justifyContent: "center",
  margin: "8px 0px",
  ["@media only screen and (min-width: 600px)"]: {
    margin: "16px 0px",
  },
  ["@media only screen and (min-width: 900px)"]: {
    margin: "24px 0px",
  },
});

const StyledBtnSendHeart = styled("div")({
  backgroundSize: "cover",
  height: 100,
  borderRadius: 8,
  backgroundPosition: "bottom",
  display: "flex",
  "& button": {
    width: "100%",
    color: "#FFFFFF",
    paddingLeft: "20%",
    fontSize: "1.5rem",
    ["@media only screen and (min-width: 600px)"]: {
      fontSize: "1.75rem",
    },
    ["@media only screen and (min-width:900px)"]: {
      fontSize: "2rem",
    },
  },
  ["@media only screen and (max-width: 600px)"]: {
    width: 200,
    height: 80,
  },
  ["@media only screen and (min-width: 600px)"]: {
    width: 280,
  },
  ["@media only screen and (min-width:900px)"]: {
    width: 300,
  },
  ["@media only screen and (min-width:1200px)"]: {
    width: 320,
  },
});

const StyledRoot = styled("div")({
  display: "flex",
  flexDirection: "column",
  padding: 16,
  marginTop: 60,
  margin: "0px auto",
  minWidth: 0,
});

const HeartTransfer = () => {
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const [openSend, setOpenSend] = React.useState(false);

  const handleClickOpenSend = () => {
    setOpenSend(true);
  };

  const handleCloseSend = () => {
    setOpenSend(false);
  };

  return (
    <StyledRoot className={"page"}>
      {userProfile && (
        <Stack
          direction={{ xs: "column", sm: "row" }}
          justifyContent="space-between"
        >
          <HeaderPage textLabel={"รับส่งหัวใจ"} icon={SendHeartCoinHeader} />
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <Typography marginRight={1}>
              {`หัวใจที่เหลือ : ${userProfile.sendHeartBalance}/${userProfile.sendHeartQuota}`}
            </Typography>
            <HeartCoin width={24} />
          </div>
        </Stack>
      )}

      <StyledDivider />
      <Container>
        <WrapBtnSendHeart>
          <StyledBtnSendHeart
            style={{ backgroundImage: `url(${BtnSendHeart})` }}
          >
            <Button onClick={handleClickOpenSend}>ส่งหัวใจ</Button>
          </StyledBtnSendHeart>
        </WrapBtnSendHeart>
        {openSend && (
          <DialogSentHeart open={openSend} handleClose={handleCloseSend} />
        )}
        <HeartTransferList />
      </Container>
    </StyledRoot>
  );
};

export default HeartTransfer;
