import {
  COMPANY_SEND_COIN_FETCHING,
  COMPANY_SEND_COIN_FAILED,
  COMPANY_SEND_COIN_SUCCESS,
} from "./types";
import companySendCoin from "../services/company-send-coin.service";

export const getCompanySendCoin = (query) => async (dispatch) => {
  try {
    dispatch({ type: COMPANY_SEND_COIN_FETCHING });
    const res = await companySendCoin.getCompanySendCoin(query);
    if (res) {
      dispatch({ type: COMPANY_SEND_COIN_SUCCESS, payload: res.data });
    }
  } catch (err) {
    dispatch({ type: COMPANY_SEND_COIN_FAILED });
  }
};

export const addCompanySendCoin = (formData) => async (dispatch) => {
  try {
    const res = await companySendCoin.addCompanySendCoin(formData);
    if (res) {
      return res;
    }
  } catch (err) {
    dispatch({ type: COMPANY_SEND_COIN_FAILED });
  }
};

export const deleteCompanySendCoin = (id) => async (dispatch) => {
  try {
    const res = await companySendCoin.deleteCompanySendCoin(id);
    if (res) {
      return res;
    }
  } catch (err) {
    dispatch({ type: COMPANY_SEND_COIN_FAILED });
  }
};
