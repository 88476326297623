import React, { useState } from "react";
import {
  Box,
  Button,
  Card,
  Container,
  Grid,
  ImageList,
  ImageListItem,
  styled,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import TiptapDetail from "../../../shared/reward/tiptapDetail";
import LoadingIcon from "../../../shared/general/loadingIcon";
import TableReward from "./tableReward";
import { useUniReward } from "../../../../../quries/uniReward";

const StyledRoot = styled("div")({
  display: "flex",
  flexDirection: "column",
  padding: 15,
  marginTop: 60,
  margin: "0px auto",
  minWidth: 350,
});

function DetailUniReward() {
  const { id: idUniReward } = useParams();
  const { data: reward, isPending } = useUniReward({ idUniReward })

  const [selectImage, setImgae] = useState(null);

  const handleChangeImage = (urlImage) => {
    setImgae(urlImage);
  };

  if (isPending) {
    return (
      <StyledRoot className={"page"}>
        <LoadingIcon />
      </StyledRoot>
    ) 
  }

  return (
    <StyledRoot className={"page"}>
      <Container>
        <Grid container spacing={3} marginBottom={4}>
          <Grid item xs={12} md={6}>
            <Box sx={{ border: "1px solid #bdbdbd",  height: "480px" }}>
              <img
                style={{ width: "100%", height: "100%", objectFit: "contain" }}
                alt={"reward"}
                src={selectImage ? selectImage : reward.imageURL}
              />
            </Box>
            <ImageList
              //   sx={{ width: 500, height: 450 }}
              cols={5}
              //   rowHeight={164}
            >
              {[{ imageURL: reward.imageURL }, ...reward.images].map((item) => (
                <Box
                  sx={{
                    border: "solid",
                    borderWidth: 1,
                    borderColor: "#bdbdbd",
                    display: 'flex',
                  }}
                  onClick={() => handleChangeImage(item.imageURL)}
                  key={item.idUniRewardImage}
                >
                  <ImageListItem>
                    <img
                      src={item.imageURL}
                      alt={item.idUniRewardImage}
                      loading="lazy"
                      style={{ objectFit: 'contain' }}
                    />
                  </ImageListItem>
                </Box>
              ))}
            </ImageList>
          </Grid>
          <Grid item xs={12} md={6}>
            <Button 
              component={Link} 
              to={`/uni-team/reward/edit/${idUniReward}`} 
              startIcon={<EditIcon />}
            >
              แก้ไขรายละเอียด
            </Button>
          <TiptapDetail detail={reward.detail} />
          </Grid>
          <Grid item xs={12}>
            <Card elevation={2}>
              <TableReward reward={reward} />
            </Card>
          </Grid>
        </Grid>
      </Container>
    </StyledRoot>
  );
}

export default DetailUniReward;
