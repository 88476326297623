import React, { useState, useEffect, Fragment } from "react";
import { styled } from "@mui/material/styles";
import {
  Grid,
  Typography,
  Chip,
  Snackbar,
  Alert,
  Autocomplete,
  TextField,
  CircularProgress,
} from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { useSelector } from "react-redux";
import FilterListIcon from "@mui/icons-material/FilterList";
import IconButton from "@mui/material/IconButton";
import Drawer from "@mui/material/Drawer";
import MoraleYearlyFilter from "./filter/filterMoraleDashboard";
import {
  useEmployeeLevel,
  useEmploymentType,
  useOrganization,
} from "../../../../quries/company";
import { useMoraleCompany, useMoraleKPI } from "../../../../quries/morale";
import MoraleDetail from "./detail";

const StyledAutocomplete = styled(Autocomplete)({
  backgroundColor: "rgba(255,255,255,0.08)",
  "& .MuiOutlinedInput-root": {
    color: "#ffffff",
  },
  fieldset: {
    borderColor: "#ffffff",
  },
  "& .MuiFormLabel-root,& .MuiFormLabel-root.Mui-focused": {
    color: "#ffffff",
  },
});

const RenderSelectedFilter = ({ selectedFilter, handleToggle }) => {
  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
      {Object.keys(selectedFilter)
        .filter((element) =>
          selectedFilter[element].itemList?.some((item) => item.checked)
        )
        .map((element) => {
          return (
            <div style={{ display: "flex", gap: "8px" }}>
              <Chip icon={<FilterAltIcon />} label={element} color="primary" />
              {selectedFilter[element].itemList
                .filter((item) => item.checked)
                .map((item) => (
                  <Chip
                    variant="outlined"
                    label={item.text}
                    sx={{ color: "#ffffff" }}
                    onClick={() => handleToggle(element, item.text)}
                  />
                ))}
            </div>
          );
        })}
    </div>
  );
};

const Monthly = () => {
  const { result: userProfile } = useSelector((state) => state.userProfile);

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [moraleSelected, setMoraleSelected] = useState(null);
  const [companySelected, setCompanySelected] = useState(null);
  const [departmentSelected, setDepartmentSelected] = useState({
    departmentName: "All Department",
    idDepartment: 0,
  });
  const [sectionSelected, setSectionSelected] = useState({
    sectionName: "All Section",
    idSection: 0,
  });

  const [openFilter, setOpenFilter] = useState(false);
  const [filterSelected, setFilterSelected] = useState(null);
  const toggleDrawer = () => {
    setOpenFilter(!openFilter);
  };

  const organization = useOrganization();
  const moraleCompany = useMoraleCompany({
    idCompany: companySelected?.idCompany,
  });
  const employmentType = useEmploymentType({
    idCompany: companySelected?.idCompany,
  });
  const employmentLevel = useEmployeeLevel({
    idCompany: companySelected?.idCompany,
  });

  useEffect(() => {
    if (moraleCompany?.data?.length > 0) {
      setMoraleSelected(moraleCompany.data[0]);
    }
  }, [moraleCompany.data]);

  const handleToggle = (category, item) => {
    setFilterSelected((prevFilters) => {
      const updatedFilters = { ...prevFilters };
      const targetItem = updatedFilters[category].itemList.find(
        (i) => i.text === item
      );
      if (targetItem) {
        targetItem.checked = !targetItem.checked;
      }
      return updatedFilters;
    });
  };

  useEffect(() => {
    const filterData = {
      gender: {
        label: "Gender",
        itemList: [
          { value: "Female", text: "Female", checked: false },
          { value: "Male", text: "Male", checked: false },
        ],
      },
      generation: {
        label: "Generation",
        itemList: [
          {
            value: "babyBoomer",
            text: "Baby Boomer",
            checked: false,
          },
          {
            value: "genX",
            text: "Generation X",
            checked: false,
          },
          {
            value: "genY",
            text: "Generation Y",
            checked: false,
          },
          {
            value: "genZ",
            text: "Generation Z",
            checked: false,
          },
          {
            value: "alpha",
            text: "Alpha",
            checked: false,
          },
        ],
      },
      jobLevel: {
        label: "Job Level",
        itemList: employmentLevel?.data?.map((item) => ({
          value: item.idLevel,
          text: item.levelName,
          checked: false,
        })),
      },
      jobType: {
        label: "Job Type",
        itemList: employmentType?.data?.map((item) => ({
          value: item.idEmploymentType,
          text: item.employmentTypeName,
          checked: false,
        })),
      },
    };

    setFilterSelected({ ...filterData });
  }, [employmentType.data, employmentLevel.data]);

  const moraleKPI = useMoraleKPI({
    idMorale: moraleCompany?.data?.[0]?.idMorale,
    query: {},
  });

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const handleClickApply = (filterData) => {
    setFilterSelected({ ...filterData });
  };

  const handleClearFilter = () => {
    setFilterSelected({
      gender: {
        label: "Gender",
        itemList: [
          { value: "Female", text: "Female", checked: false },
          { value: "Male", text: "Male", checked: false },
        ],
      },
      generation: {
        label: "Generation",
        itemList: [
          {
            value: "babyBoomer",
            text: "Baby Boomer",
            checked: false,
          },
          {
            value: "genX",
            text: "Generation X",
            checked: false,
          },
          {
            value: "genY",
            text: "Generation Y",
            checked: false,
          },
          {
            value: "genZ",
            text: "Generation Z",
            checked: false,
          },
          {
            value: "alpha",
            text: "Alpha",
            checked: false,
          },
        ],
      },
      jobLevel: {
        label: "Job Level",
        itemList: employmentLevel?.data?.map((item) => ({
          value: item.idLevel,
          text: item.levelName,
          checked: false,
        })),
      },
      jobType: {
        label: "Job Type",
        itemList: employmentType?.data?.map((item) => ({
          value: item.idEmploymentType,
          text: item.employmentTypeName,
          checked: false,
        })),
      },
    });
  };

  useEffect(() => {
    if (organization.isSuccess) {
      setCompanySelected(organization.data[0]);
    }
  }, [organization.isSuccess]);

  if (organization.isPending) {
    return <CircularProgress />;
  }

  return (
    <Fragment>
      <Typography variant="h5" gutterBottom style={{ color: "#e2e2e2" }}>
        สรุปผลการประเมิน
      </Typography>
      <Fragment>
        {userProfile?.roles?.includes("ROLE_ADMIN") &&
          employmentType.data &&
          employmentLevel.data && (
            <>
              <div>
                {filterSelected && (
                  <RenderSelectedFilter
                    selectedFilter={filterSelected}
                    handleToggle={handleToggle}
                  />
                )}
              </div>
            </>
          )}
        <Grid container spacing={2} alignItems="center" marginBottom={1}>
          <Grid item xs={12} sm={6} md={4}>
            <StyledAutocomplete
              disableClearable
              value={companySelected}
              options={organization.data}
              onChange={(event, newValue) => {
                setCompanySelected(newValue);
                setMoraleSelected(null);
                setDepartmentSelected(null);
                setSectionSelected(null);
              }}
              getOptionLabel={(option) => option.companyName}
              isOptionEqualToValue={(option, value) =>
                option.idCompany === value.idCompany
              }
              renderInput={(params) => (
                <TextField {...params} label="Company" />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <StyledAutocomplete
              disableClearable
              value={moraleSelected}
              options={moraleCompany.data}
              loading={moraleCompany.isPending}
              onChange={(event, newValue) => setMoraleSelected(newValue)}
              getOptionLabel={(option) => option.moraleName}
              isOptionEqualToValue={(option, value) =>
                option.idMorale === value.idMorale
              }
              renderInput={(params) => <TextField {...params} label="Morale" />}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "8px",
              }}
            >
              <Fragment>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    padding: "15px",
                    width: "100%",
                  }}
                >
                  <IconButton
                    aria-label="filter"
                    onClick={toggleDrawer}
                    size="large"
                    style={{
                      backgroundColor: "rgb(65,105,225)",
                      color: "white",
                    }}
                  >
                    <FilterListIcon fontSize="large" />
                  </IconButton>
                </div>

                <Drawer anchor="right" open={openFilter} onClose={toggleDrawer}>
                  <MoraleYearlyFilter
                    filter={filterSelected}
                    handleClickApply={handleClickApply}
                    handleClearFilter={handleClearFilter}
                    handleCloseDrawer={toggleDrawer}
                  />
                </Drawer>
              </Fragment>
            </div>
          </Grid>
        </Grid>
        {moraleSelected && (
          <MoraleDetail
            idMorale={moraleSelected.idMorale}
            filterSelected={filterSelected}
          />
        )}
      </Fragment>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="error"
          sx={{ width: "100%" }}
        >
          โปรดเลือกชุดคำถาม
        </Alert>
      </Snackbar>
    </Fragment>
  );
};

export default Monthly;
