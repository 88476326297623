import React, { useCallback, useEffect } from "react";
import { Grid, styled, Typography } from "@mui/material";
import DashboardIcon from "@mui/icons-material/Dashboard";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import dayjs from "dayjs";
import { BarChart } from "@mui/x-charts/BarChart";
import FeelingIcon from "../../shared/feelingIcon";
import unicornCry from "./assets/Unicorn-Mascot-Cry-square.png";
import unicornHeart from "./assets/Unicorn-Mascot-Heart-square.png";
import unicornIndifferently from "./assets/Unicorn-Mascot-Indifferently-square.png";
import TableMoraleDaily from "./TableDaily";
import { utils, writeFileXLSX } from "xlsx";
import { Tooltip } from "recharts";
import DailyGaugeChart from "./DailyGaugeChart";
import DailyDepartmentSectionTable from "./DailyDepartmentSectionTable";
import Top3StrengthDailyTable from "./Top3StrengthDailyTable";
import Top3WeaknessDailyTable from "./Top3WeaknessDailyTable";
import { useDispatch, useSelector } from "react-redux";
import { getMoraleKPIDaily } from "../../../../actions/moraleKPI";
import LoadingIcon from "../../shared/general/loadingIcon";

const WrapFeeling = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: 16,
  borderRadius: 8,
  backgroundColor: "#282828",
});

const feelings = [
  { feeling: "veryhappy" },
  { feeling: "happy" },
  { feeling: "littlehappy" },
  { feeling: "littlebad" },
  { feeling: "bad" },
  { feeling: "verybad" },
];

const feelingCapital = {
  veryhappy: "Very happy",
  happy: "Happy",
  littlehappy: "Little happy",
  littlebad: "Little bad",
  bad: "Bad",
  verybad: "Very bad",
};

const feelingScore = (averageScore) => {
  console.log("Input averageScore:", averageScore);
  if (averageScore === 100) {
    return "veryhappy";
  } else if (averageScore >= 80) {
    return "happy";
  } else if (averageScore >= 60) {
    return "littlehappy";
  } else if (averageScore >= 40) {
    return "littlebad";
  } else if (averageScore >= 20) {
    return "bad";
  } else {
    return "verybad";
  }
};

const DailyDetail = ({ companySelected, idDepartment, idSection, date }) => {
  const dispatch = useDispatch();
  const { result: moraleKpiYear, isFetching } = useSelector(state => state.moraleKpiYear);

  const unicornImage = (x) => {
    if (x >= 80) {
      return unicornHeart;
    } else if (x >= 50) {
      return unicornIndifferently;
    } else {
      return unicornCry;
    }
  };

  const filterData =
    moraleKpiYear
      ?.filter((item) => {
        return idDepartment === 0 ? true : item.idDepartment === idDepartment;
      })
      ?.filter((item) => {
        return idSection === 0 ? true : item.idSection === idSection;
      }) || [];

  const exportFile = useCallback(() => {
    const data = filterData?.reduce((acc, cur) => {
      const employee = {
        employeeID: cur.employeeID,
        firstname_TH: cur.firstname_TH,
        lastname_TH: cur.lastname_TH,
        positionName: cur.positionName,
        sectionName: cur.sectionName,
        departmentName: cur.departmentName,
        divisionName: cur.divisionName,
        averageScore: cur.averageScore,
      };

      const temp = Object.assign(
        employee,
        ...cur.questionTopics.map((item) => ({
          [item.questionTopic]: item.averageScore,
        }))
      );
      acc.push(temp);
      return acc;
    }, []);

    const ws = utils.json_to_sheet(data);
    utils.sheet_add_aoa(
      ws,
      [
        [
          "รหัสพนักงาน",
          "ชื่อ",
          "นามสกุล",
          "ตำแหน่ง",
          "ฝ่าย",
          "ส่วน",
          "แผนก",
          "average",
          ...filterData[0].questionTopics.map((item) => item.questionTopic),
        ],
      ],
      { origin: "A1" }
    );

    ws["!cols"] = [
      { wch: 10 },
      { wch: 25 },
      { wch: 25 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
      { wch: 10 },
      ...filterData[0].questionTopics.map((item) => ({ wch: 20 })),
    ];

    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws);
    writeFileXLSX(
      wb,
      `KPI Daily ${dayjs(date).format("MMMM YYYY")} ${
        companySelected.companyName
      }.xlsx`
    );
  }, [filterData]);

  const scoreData = filterData?.reduce(
    (acc, cur) => {
      if (cur.averageScore) {
        if (cur.averageScore === 100) {
          acc.veryhappy++;
        } else if (cur.averageScore >= 80) {
          acc.happy++;
        } else if (cur.averageScore >= 60) {
          acc.littlehappy++;
        } else if (cur.averageScore >= 40) {
          acc.littlebad++;
        } else if (cur.averageScore >= 20) {
          acc.bad++;
        } else {
          acc.verybad++;
        }

        acc.totalScore += Number(cur.averageScore);
        acc.dataLength++;
      }

      return acc;
    },
    {
      dataLength: 0,
      totalScore: 0,
      veryhappy: 0,
      happy: 0,
      littlehappy: 0,
      littlebad: 0,
      bad: 0,
      verybad: 0,
    }
  );

  const scoreTopic = filterData?.reduce((acc, cur) => {
    for (const x of cur.questionTopics) {
      if (!acc[x.idQuestionTopic]) {
        acc[x.idQuestionTopic] = { sumScore: 0, dataLength: 0 };
      }
      if (x.averageScore) {
        acc[x.idQuestionTopic].sumScore += Number(x.averageScore);
        acc[x.idQuestionTopic].dataLength++;
      }
    }
    return acc;
  }, {});

  const totalAverageScore =
    scoreData?.dataLength > 0
      ? Math.round(scoreData.totalScore / scoreData.dataLength)
      : 0;

  const averageScoreTopic = Object.keys(scoreTopic).map((topic) => {
    return scoreTopic[topic].dataLength > 0
      ? scoreTopic[topic].sumScore / scoreTopic[topic].dataLength
      : 0;
  });

  const maxLengthTopic = filterData?.[0]?.questionTopics.reduce(
    (acc, cur) =>
      cur.questionTopic.length > acc ? cur.questionTopic.length : acc,
    0
  );

  const top3Topics = filterData
    .flatMap((item) => item.questionTopics)
    .reduce((acc, topic) => {
      if (!acc[topic.questionTopic]) {
        acc[topic.questionTopic] = { sum: 0, count: 0 };
      }
      acc[topic.questionTopic].sum += topic.averageScore;
      acc[topic.questionTopic].count += 1;
      return acc;
    }, {});

  const top3 = Object.entries(top3Topics)
    .map(([topic, { sum, count }]) => ({
      topic,
      averageScore: sum / count,
    }))
    .sort((a, b) => b.averageScore - a.averageScore)
    .slice(0, 3);

  useEffect(() => {
    dispatch(getMoraleKPIDaily({
      idCompany: companySelected.idCompany,
      year: dayjs(date).year(),
      month: dayjs(date).month() + 1,
    }))
  }, [companySelected, date])

  if (isFetching) {
    return <LoadingIcon />;
  }

  return (
    <div>
      {filterData.length === 0 ? (
        <Typography variant="h6" color={"#ffffff"}>
          {"ไม่พบข้อมูล"}
        </Typography>
      ) : (
        <>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: 6,
            }}
          >
            <WrapFeeling>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "1rem",
                  flexWrap: "wrap",
                }}
              >
                <div
                  style={{
                    width: "80px",
                    height: "80px",
                    "@media (max-width: 600px)": {
                      width: "60px",
                      height: "60px",
                    },
                  }}
                >
                  <FeelingIcon feeling={feelingScore(totalAverageScore)} />
                </div>
                <div>
                  {/* {console.log("Total Average Score:", totalAverageScore)} */}
                  <Typography
                    variant="h6"
                    color={"#ffffff"}
                    sx={{
                      "@media (max-width: 600px)": {
                        fontSize: "1rem",
                      },
                    }}
                  >
                    {feelingCapital[feelingScore(totalAverageScore)]}
                  </Typography>
                  <Typography
                    variant="h4"
                    color={"#ffffff"}
                    sx={{
                      "@media (max-width: 600px)": {
                        fontSize: "1.5rem",
                      },
                    }}
                  >
                    {`Total score: ${totalAverageScore}%`}
                  </Typography>
                </div>
              </div>
            </WrapFeeling>

            <img
              src={unicornImage(totalAverageScore)}
              alt="unicorn"
              width={480}
              height={480}
            />
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "0.5rem",
              marginBottom: "1rem",
            }}
          >
            <DashboardIcon sx={{ color: "#ffffff" }} />
            <Typography variant="h5" color={"#ffffff"}>
              {"สัดส่วนพนักงาน"}
            </Typography>
          </div>
          <Grid
            container
            spacing={2}
            marginBottom={6}
            marginTop={1}
            justifyContent="center"
            alignItems="center"
          >
            {feelings.map((item) => (
              <Grid item key={item.feeling} xs={12} sm={6} md={4}>
                <WrapFeeling>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "1rem",
                      flexWrap: "wrap",
                    }}
                  >
                    {/* DailyGaugeChart (Gauge) */}
                    <DailyGaugeChart
                      value={Math.round(
                        (scoreData[item.feeling] / scoreData.dataLength) * 100
                      )}
                      size={{ width: 200, height: 200 }}
                    />

                    {/* Wrap for Percentage and Participants Label to ensure they are aligned */}
                    <div style={{ width: "100%", textAlign: "center" }}>
                      <Typography variant="h5" color={"#ffffff"}>
                        {`${Math.round(
                          (scoreData[item.feeling] / scoreData.dataLength) * 100
                        )}%`}
                      </Typography>
                      {/* <Typography variant="body1" color={"#ffffff"}>
                        {`${scoreData[item.feeling]} ${
                          scoreData[item.feeling] === 1
                            ? "participant"
                            : "participants"
                        }`}
                      </Typography> */}
                    </div>

                    {/* Feeling Icon */}
                    <div
                      style={{
                        width: 60,
                        height: 60,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <FeelingIcon feeling={item.feeling} />
                    </div>

                    {/* Feeling Label (e.g., "Very happy") */}
                    <Typography variant="h5" color={"#ffffff"}>
                      {feelingCapital[item.feeling]}
                    </Typography>
                  </div>
                </WrapFeeling>
              </Grid>
            ))}
          </Grid>

          <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <LeaderboardIcon sx={{ color: "#ffffff" }} />
            <Typography variant="h5" color={"#ffffff"}>
              {"หัวข้อคำถาม"}
            </Typography>
          </div>
          <div style={{ padding: "0px 16px", marginBottom: "24px" }}>
            <BarChart
              height={Math.round(filterData[0].questionTopics.length * 40)}
              margin={{ left: maxLengthTopic * 12 }}
              yAxis={[
                {
                  scaleType: "band",
                  data: filterData[0].questionTopics.map(
                    (item) => item.questionTopic
                  ),
                  tickSize: 8,
                  tickPlacement: "middle",
                },
              ]}
              xAxis={[
                {
                  tickInterval: [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
                },
              ]}
              series={[
                {
                  data: averageScoreTopic,
                  valueFormatter: (value) => `${value}%`,
                  color: "#ff9da7",
                },
              ]}
              borderRadius={8}
              layout="horizontal"
              slotProps={{
                axisTickLabel: {
                  style: {
                    fill: "#ffffff",
                    fontSize: 16,
                  },
                },
                axisTick: {
                  style: {
                    stroke: "#ffffff",
                  },
                },
                axisLine: {
                  style: {
                    stroke: "#ffffff",
                  },
                },
              }}
              grid={{ vertical: true }}
            >
              <Tooltip
                formatter={(value, name, props) => {
                  const topicId =
                    filterData[0].questionTopics[props.index].idQuestionTopic;
                  const responses = scoreTopic[topicId]?.dataLength || 0;
                  return [`${value}%`, `Responses: ${responses}`];
                }}
                cursor={{ fill: "rgba(255, 255, 255, 0.1)" }}
              />
            </BarChart>
          </div>

          <Grid sx={{ padding: "18px" }}>
            {filterData && companySelected && (
              <Top3StrengthDailyTable
                filterData={filterData}
                companyName={companySelected.companyName}
                departmentName={idDepartment}
              />
            )}
          </Grid>

          <Grid sx={{ padding: "18px" }}>
            {filterData && companySelected && (
              <Top3WeaknessDailyTable
                filterData={filterData}
                companyName={companySelected.companyName}
                departmentName={idDepartment}
              />
            )}
          </Grid>

          <Grid sx={{ padding: "18px" }}>
            <DailyDepartmentSectionTable filterData={filterData} />
          </Grid>

          <Grid sx={{ padding: "18px" }}>
            <TableMoraleDaily filterData={filterData} exportFile={exportFile} />
          </Grid>
        </>
      )}
    </div>
  );
};

export default DailyDetail;
