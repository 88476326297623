import React, { useEffect ,useState} from "react";
import { styled } from "@mui/material/styles";
import { MainCoin } from "../../../shared/typeCoin";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import { useNavigate } from 'react-router-dom';
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded';
import { 
    Typography,
     Avatar, 
     Grid ,
     Card, 
     Stack,
    } from "@mui/material";


const StyledCard = styled(Card)({
    padding: 24,
    boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
    transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    borderRadius: 20,
    "& .MuiCardContent-root": {
        padding: 24,
    },
});
const DetailProfileCoach = (prop)=>{

    const navigate = useNavigate();
    const {coachProfile} = prop
    const idCoach = coachProfile.idCoach;


    const handleClickNavigateToAppointment = () => {
    
        if (window.location.pathname !== '/coaching/profile') {
            navigate('/coaching/profile#appointment', { state: { idCoach }});
        } else {
            const appointmentSection = document.getElementById('appointment');
            if (appointmentSection) {
                appointmentSection.scrollIntoView({ behavior: 'smooth' });
            }
        }
    };

    return(
        <StyledCard>
            <Grid 
                container 
                spacing={2} 
                sx={{
                alignItems: "center",
                flexDirection: { xs: "column-reverse", md: "row" },
                }}
            >            
            <Grid item sm={12} md={9} sx={{
                padding:"24px 30px",
                alignItems: { xs: "center", md: "flex-start" },
                textAlign: { xs: "center", md: "start" }, 
                justifyContent:{xs:"center"}
                
                }}>
                <Typography variant="h4">
                    {coachProfile.firstname_TH} {coachProfile.lastname_TH}
                </Typography>
                <Typography className="field-label" color="text.secondary" marginBottom={"20px"}>
                    {coachProfile.positionName}
                </Typography>
                <Typography
                    component="p"
                    gutterBottom
                >
                    อีเมล์ : {coachProfile.email}
                </Typography>
                <Typography
                    component="p"
                    gutterBottom
                >
                    โทรศัพท์ : {coachProfile.telephoneMobile}
                </Typography>
                <Typography
                    component="p"
                    gutterBottom
                >
                    ค่าสอน/ชั่วโมง
                </Typography>
                {
                    coachProfile.isFixCoach == 1 ? (
                        <Typography className="field-label" gutterBottom>
                            ไม่มีค่าใช้จ่าย
                        </Typography>
                    ):(
                        <Stack direction="row" spacing={2} alignItems={"center"} marginBottom={"20px"}
                            sx={{ justifyContent: { xs: "center", md: "flex-start" } }} 

                        
                        >
                            <MainCoin width={32}/>
                            <Typography className="field-label" color="text.secondary" gutterBottom>
                                x {coachProfile.price}
                            </Typography>
                        </Stack>
                    )
                }
                <ButtonBlue
                    variant="contained"
                    color="primary"
                    type="submit"
                    startIcon={<CalendarMonthRoundedIcon />}
                    onClick={handleClickNavigateToAppointment}
                >
                    นัดหมาย
                </ButtonBlue>
                </Grid>
                <Grid item xs={12} sm={12} md={3} sx={{padding:"24px"}}>
                <div style={{display:"flex",justifyContent:"center",padding:"16px"}}>
                    <div style={{
                        display:"inline-flex", 
                        justifyContent:"center",
                        alignItems:"center",
                        padding:"8px",
                        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                        borderRadius:"50%"
                        }}>
                    <Avatar
                        src={coachProfile.profileImageURL}
                        style={{ width: 220, height: 220 }} 
                        />
                    </div>
                </div>
                </Grid>
            </Grid>
        </StyledCard>
    )


}

export default DetailProfileCoach; 