import React from "react";
import { Container, Divider, styled } from "@mui/material";
import HeaderPage from "../../../shared/header/headerPage";
import RewardIcon from "./assets/gifts.png";
import RewardCards from "./rewardCards";
import "./index.css";

const StyledDivider = styled(Divider)({
  width: "100%",
  margin: "10px 0",
})

const StyledRoot = styled('div')({
  display: "flex",
  flexDirection: "column",
  padding: 15,
  marginTop: 60,
  margin: "0px auto",
  minWidth: 350,
})

export default function Reward() {
  return (
    <StyledRoot className={'page'}>
      <HeaderPage textLabel={"จัดการของรางวัล"} icon={RewardIcon} />
      <StyledDivider />
      <Container>
        <RewardCards />
      </Container>
    </StyledRoot>
  );
}
