import React, { useState, useEffect, Fragment } from "react";
import { styled } from "@mui/material/styles";
import Avatar from "@mui/material/Avatar";
import Paper from "@mui/material/Paper";
import { Typography } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Grid from "@mui/material/Grid";
import ListItemText from "@mui/material/ListItemText";
import clsx from "clsx";
// Styles
//import "./AllLeaderBoard.scss";

// Icons
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import MinimizeIcon from "@mui/icons-material/Minimize";

// Backdrop and Notification
import Skeleton from '@mui/material/Skeleton';

import RankIcon from "./assets/rank 2.png";
import Gold from "./assets/gold.png";
import Silver from "./assets/silver.png";
import Bronze from "./assets/bronze.png";
import { HeartCoin, MainCoin } from "../../shared/typeCoin";
import { useDispatch, useSelector } from "react-redux";
import { getAllEmployees } from "../../../../actions/user";
import { getDepartment } from "../../../../actions/department";
import { getCoinLeaderboard, getHeartLeaderboard } from "../../../../actions/leaderboard";

const LargeImage = styled(Avatar)({
  width: 65,
  height: 65,
  margin: "auto",
})

const LargeImageOne = styled(Avatar)({
  width: 90,
  height: 90,
  margin: "auto",
})

const WrapRankTopThree = styled('div')({
  display: "flex",
  justifyContent: "space-evenly",
  marginBottom: 20,
  ["@media only screen and (max-width: 600px)"]: {
    height: 400
  },
  ["@media only screen and (min-width:600px)"]: {},
  ["@media only screen and (min-width:768px)"]: {},
  ["@media only screen and (min-width:992px)"]: {},
  ["@media only screen and (min-width:1200px)"]: {},
})

const StyledRankOne = styled(Paper)({
  textAlign: "center",
  padding: 16,
  height: "fit-content",
  width: 140,
  backgroundColor: "rgba(255,255,255,.5)",
})

const StyledRankTwoThree = styled(Paper)({
  textAlign: "center",
  padding: 16,
  marginTop: 30,
  minWidth: 100,
  width: 140,
  backgroundColor: "rgba(255,255,255,.5)",
  height: "fit-content",
  ["@media only screen and (max-width: 600px)"]: {
    marginTop: "auto",
  },
  ["@media only screen and (min-width:600px)"]: {},
  ["@media only screen and (min-width:768px)"]: {},
  ["@media only screen and (min-width:992px)"]: {},
  ["@media only screen and (min-width:1200px)"]: {},
})

const WrapRankScoreTop = styled('div')({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
})

const StyledRankOther = styled(ListItem)({
  backgroundColor: "rgba(255,255,255,.5)",
  margin: "8px 0",
  borderRadius: 25,
})

const StyledImgMedals = styled('img')({
  top: -32,
  right: -30,
  position: "absolute",
})

// Leaderboard Component
function LeaderBoardList(props) {
  const { title, type, data } = props;

  if(data.length === 0) {
    return null
  }

  return (
    <div className="d-flex justify-content-center" style={{ margin: 0 }}>
      <div className={"leaderboard-" + type} style={{ width: "100%" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: 20,
            marginTop: 20,
          }}
        >
          <img src={RankIcon} width="35" alt="Trophy" />
          <Typography
            variant="h4"
            style={{ color: "#3c3c3c", paddingLeft: 10 }}
          >
            {title}
          </Typography>
        </div>
        <div>
          <WrapRankTopThree>
            <StyledRankTwoThree>
              <div style={{ position: "relative" }}>
                <StyledImgMedals src={Silver} width="30" />
              </div>
              <LargeImage
                alt="Remy Sharp"
                src={data[1]?.imageURL}
              />
              <Typography
                style={{ marginTop: 10, fontSize: 16, lineHeight: 1.2 }}
                noWrap
              >
                {`${data[1]?.firstname_TH} ${data[1]?.lastname_TH}`}
              </Typography>
              <Typography
                style={{ fontSize: 12, lineHeight: 1.2 }}
                color="textSecondary"
              >
                {data[1]?.departmentName}
              </Typography>
              <WrapRankScoreTop>
                {type === "heart" ? <HeartCoin width={25} /> : <MainCoin width={25} />}

                <Typography
                  style={{ fontSize: 32, paddingLeft: 5, lineHeight: 1.2 }}
                >
                  {data[1]?.amount}
                </Typography>
              </WrapRankScoreTop>
            </StyledRankTwoThree>
            <StyledRankOne elevation={0}>
              <div style={{ position: "relative" }}>
                <StyledImgMedals src={Gold} width="30" />
              </div>
              <LargeImageOne
                alt="Remy Sharp"
                src={data[0]?.imageURL}
              />
              <Typography
                style={{ marginTop: 10, fontSize: 16, lineHeight: 1.2 }}
                noWrap
              >
                {`${data[0]?.firstname_TH} ${data[0]?.lastname_TH}`}
              </Typography>
              <Typography
                style={{ fontSize: 12, lineHeight: 1.2 }}
                color="textSecondary"
              >
                {data[0]?.departmentName}
              </Typography>
              <WrapRankScoreTop>
                {type === "heart" ? <HeartCoin width={25} /> : <MainCoin width={25} />}
                <Typography
                  style={{ fontSize: 32, paddingLeft: 5, lineHeight: 1.2 }}
                >
                  {data[0]?.amount}
                </Typography>
              </WrapRankScoreTop>
            </StyledRankOne>
            <StyledRankTwoThree>
              <div style={{ position: "relative" }}>
                <StyledImgMedals src={Bronze} width="30" />
              </div>
              <LargeImage
                alt="Remy Sharp"
                src={data[2]?.imageURL}
              />
              <Typography
                style={{ marginTop: 10, fontSize: 16, lineHeight: 1.2 }}
                noWrap
              >
                {`${data[2]?.firstname_TH} ${data[2]?.lastname_TH}`}
              </Typography>
              <Typography
                style={{ fontSize: 12, lineHeight: 1.2 }}
                color="textSecondary"
              >
                {data[2]?.departmentName}
              </Typography>
              <WrapRankScoreTop>
                {type === "heart" ? <HeartCoin width={25} /> : <MainCoin width={25} />}
                <Typography
                  style={{ fontSize: 32, paddingLeft: 5, lineHeight: 1.2 }}
                >
                  {data[2]?.amount}
                </Typography>
              </WrapRankScoreTop>
            </StyledRankTwoThree>
          </WrapRankTopThree>
        </div>
        <div>
          <List
            aria-label="ranking coin"
            style={{ maxWidth: 475, margin: "auto" }}
          >
            {data
              .slice(3, 10)
              .map((value, index) => {
                return (
                  <StyledRankOther key={index}>
                    <div
                      style={{
                        textAlign: "center",
                        width: 30,
                        height: 35,
                        position: "relative",
                        marginRight: 10,
                      }}
                    >
                      <Typography style={{}}>{index + 4}</Typography>
                      {/* {value.rankIndex === "up" && (
                        <ArrowDropUpIcon
                          fontSize="large"
                          style={{
                            color: "#4caf50",
                            position: "absolute",
                            top: 10,
                            right: -3,
                          }}
                        />
                      )}
                      {value.rankIndex === "down" && (
                        <ArrowDropDownIcon
                          fontSize="large"
                          style={{
                            color: "#f44336",
                            position: "absolute",
                            top: 10,
                            right: -3,
                          }}
                        />
                      )}
                      {value.rankIndex === "same" && (
                        <MinimizeIcon
                          fontSize="large"
                          style={{
                            color: "rgb(160 160 160)",
                            position: "absolute",
                            top: -4,
                            right: -3,
                          }}
                        />
                      )} */}
                    </div>
                    <Avatar alt="userimage" src={value.imageURL} />
                    <ListItemText
                      style={{ fontSize: 22, marginLeft: 10 }}
                      primary={`${value.firstname_TH} ${value.lastname_TH}`}
                      secondary={value.departmentName}
                    />
                    <WrapRankScoreTop>
                      {type === "heart" ? (
                        <HeartCoin width={25} />
                      ) : (
                        <MainCoin width={25} />
                      )}

                      <Typography
                        variant="subtitle1"
                        style={{ fontSize: 32, paddingLeft: 5 }}
                      >
                        {value.amount}
                      </Typography>
                    </WrapRankScoreTop>
                  </StyledRankOther>
                );
              })}
          </List>
        </div>
      </div>
    </div>
  );
}
// end Leaderboard Component

const AllLeaderBoard = () => {
  const dispatch = useDispatch();
  const { result: heartLeaderboard } = useSelector((state) => state.heartLeaderboard);
  const { result: coinLeaderboard } = useSelector((state) => state.coinLeaderboard);

  useEffect(() => {
    const fecthData = () => {
      dispatch(getHeartLeaderboard());
      dispatch(getCoinLeaderboard());
    }
    fecthData()
  }, [dispatch]);

  return (
    <Fragment>
      {heartLeaderboard && coinLeaderboard ? (
        <Fragment>
          <Grid
            container
            spacing={3}
            style={{
              backgroundColor: "rgba(255, 255, 255, .3)",
              borderRadius: 8,
            }}
          >
            <Grid item xs={12} sm={6}>
              <LeaderBoardList
                title={"หัวใจ 10 อันดับแรก"}
                type={"heart"}
                data={heartLeaderboard}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <LeaderBoardList
                title={"เหรียญ 10 อันดับแรก"}
                type={"coin"}
                data={coinLeaderboard}
              />
            </Grid>
          </Grid>
        </Fragment>
      ) : (
        <div>
          <div className="row justify-content-center p-3">
            <div className="col-12 col-md-4 text-center">
              <Skeleton
                variant="rectangular"
                width="100%"
                height={70}
                animation="wave"
              />
            </div>
          </div>
          <div className="row justify-content-center px-3 pb-3">
            <div className="col-12 col-md-4 text-center">
              <Skeleton
                variant="rectangular"
                width="100%"
                height={50}
                animation="wave"
              />
              <Skeleton width="100%" animation="wave" />
              <Skeleton
                variant="rectangular"
                width="100%"
                height={550}
                animation="wave"
              />
            </div>
            <div className="col-12 col-md-4 text-center">
              <Skeleton
                variant="rectangular"
                width="100%"
                height={50}
                animation="wave"
              />
              <Skeleton width="100%" animation="wave" />
              <Skeleton
                variant="rectangular"
                width="100%"
                height={550}
                animation="wave"
              />
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default AllLeaderBoard;
