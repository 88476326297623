import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Brightness1Icon from "@mui/icons-material/Brightness1";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import { Avatar, Chip, CircularProgress, Typography } from "@mui/material";
import {
  useCancelRedeemTransaction,
  useRedeemTransactions,
  useUpdateRedeemTransaction,
} from "../../../../quries/redeemTransaction";
import monthOnlyOperators from "../../shared/dataGridFilter/monthOnlyOperators";
import dayjs from "dayjs";
import { toast } from "sonner";
import StatusMenu from "./StatusMenu";
import DialogStatus from "./DialogStatus";

const redeemStatus = {
  1: { status: "กำลังตรวจสอบ", color: "#1e88e5" },
  2: { status: "รอมารับรางวัล", color: "#fb8c00" },
  3: { status: "รับรางวัลแล้ว", color: "#43a047" },
  4: { status: "ยกเลิกรายการ", color: "#e53935" },
};

// function CustomToolbar() {
//   return (
//     <GridToolbarContainer>
//       <GridToolbarFilterButton />
//       <GridToolbarExport
//         csvOptions={{
//           fileName: "redeemTransaction",
//           delimiter: ",",
//           fields: [
//             "id",
//             "fullName",
//             "name",
//             "price",
//             "quantity",
//             "totalValue",
//             "redeemDate",
//           ],
//           utf8WithBom: true,
//         }}
//         printOptions={{ disableToolbarButton: true }}
//       />
//     </GridToolbarContainer>
//   );
// }

const RedeemTransactionGrid = ({ data }) => {
  const [openChangeDate, setOpenChangeDate] = useState({
    isOpen: false,
    redeemSelected: null,
  });

  const handleOpenDate = (redeemSelected) => {
    setOpenChangeDate({
      isOpen: true,
      redeemSelected,
    })
  };

  const handleCloseDate = () => {
    setOpenChangeDate({
      isOpen: false,
      redeemSelected: null,
    })
  };

  const { mutate: updateRedeemTransaction } = useUpdateRedeemTransaction();
  const { mutate: cancelRedeemTransaction } = useCancelRedeemTransaction();

  const handleChangeStatusRedeem = (formData) => {
    updateRedeemTransaction(formData, {
      onSuccess: () => toast.success("สำเร็จ"),
      onError: () => toast.error("เกิดข้อผิดพลาด"),
      onSettled: () => handleCloseDate(),
    });
  };

  const cancelStatusRedeem = (idRedeem) => {
    cancelRedeemTransaction(idRedeem, {
      onSuccess: () => toast.success("สำเร็จ"),
      onError: () => toast.error("เกิดข้อผิดพลาด"),
    });
  };

  const columns = [
    {
      field: "idRedeem",
      headerName: "รหัสรายการ",
      width: 80,
      headerClassName: "super-app-theme--header",
      sortable: false,
    },
    {
      field: "fullname",
      headerName: "ชื่อ-นามสกุล",
      headerClassName: "super-app-theme--header",
      width: 240,
      sortable: false,
      disableExport: true,
      renderCell: (params) => (
        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
          <Avatar
            alt={"userImage"}
            src={params.row.imageURL}
          />
          <Typography>{`${params.row.firstname_TH} ${params.row.lastname_TH}`}</Typography>
        </div>
      ),
    },
    {
      field: "name",
      headerName: "ชื่อของรางวัล",
      width: 240,
      headerClassName: "super-app-theme--header",
      sortable: false,
    },
    {
      field: "price",
      headerName: "มูลค่า/หน่วย",
      type: "number",
      width: 100,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "quantity",
      headerName: "จำนวน",
      type: "number",
      width: 80,
      headerClassName: "super-app-theme--header",
      filterable: false,
    },
    {
      field: "totalValue",
      headerName: "มูลค่ารวม",
      type: "number",
      width: 80,
      headerClassName: "super-app-theme--header",
      valueGetter: (params) => params.row.price * params.row.quantity,
    },
    {
      field: "redeemDate",
      headerName: "วันที่แลกรางวัล",
      type: "date",
      width: 140,
      headerClassName: "super-app-theme--header",
      valueGetter: (params) => dayjs(params.value),
      valueFormatter: ({ value }) => dayjs(value).format("DD-MM-YYYY"),
      renderCell: (params) => dayjs(params.value).format("DD-MM-YYYY HH:mm"),
      filterOperators: monthOnlyOperators,
    },
    {
      field: "receivedDate",
      headerName: "วันที่รับรางวัล",
      type: "date",
      width: 110,
      headerClassName: "super-app-theme--header",
      valueGetter: (params) => dayjs(params.value),
      valueFormatter: ({ value }) =>
        value ? dayjs(value).format("DD-MM-YYYY") : "ยังไม่กำหนด",
      renderCell: (params) =>
        dayjs(params.value).isValid()
          ? dayjs(params.value).format("DD-MM-YYYY")
          : "ยังไม่กำหนด",
      filterOperators: monthOnlyOperators,
    },
    {
      field: "idRedeemStatus",
      headerName: "สถานะ",
      width: 160,
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <Chip
            icon={
              <Brightness1Icon
                style={{
                  color: redeemStatus[params.row.idRedeemStatus].color,
                }}
              />
            }
            label={redeemStatus[params.row.idRedeemStatus].status}
            variant="outlined"
          />
        );
      },
      sortable: false,
      filterable: false,
    },
    {
      field: "actions",
      headerName: "จัดการ",
      type: "actions",
      width: 68,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return params.row.idRedeemStatus === 1 ||
          params.row.idRedeemStatus === 2 ? (
          <StatusMenu
            row={params.row}
            handleChangeStatusRedeem={handleChangeStatusRedeem}
            cancelStatusRedeem={cancelStatusRedeem}
            handleOpenDate={handleOpenDate}
          />
        ) : null;
      },
    },
  ];

  return (
    <>
      <div style={{ maxHeight: 600, width: "100%" }}>
        <DataGrid
          getRowId={(row) => row.idRedeem}
          rows={data}
          columns={columns}
          rowHeight={64}
          sx={{
            "& .super-app-theme--header": {
              color: "#637381",
              backgroundColor: "#f4f6f8",
            },
            ".MuiDataGrid-cell": {
              borderButtom: "0.1px solid #f2f2f2",
            },
            borderRadius: "16px",
            boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
            transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
          }}
          // slots={{ toolbar: CustomToolbar }}
          initialState={{ pagination: { paginationModel: { pageSize: 10 } } }}
          pageSizeOptions={[10, 25, 100]}
          disableColumnFilter
          disableColumnSelector
          disableDensitySelector
        />
      </div>
      {openChangeDate.isOpen && (
        <DialogStatus
          openChangeDate={openChangeDate.isOpen}
          redeemSelected={openChangeDate.redeemSelected}
          handleCloseDate={handleCloseDate}
          handleChangeStatusRedeem={handleChangeStatusRedeem}
        />
      )}
    </>
  );
};

export default RedeemTransactionGrid;
