import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Container,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  styled,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import FilterListIcon from "@mui/icons-material/FilterList";
import HowToVoteIcon from "@mui/icons-material/HowToVote";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { getAllPolls } from "../../../../../actions/poll";
import BgPage from "./assets/pexels-gradienta-7130536.jpg";
import HeaderPage from "../../../shared/header/headerPage";
import ProgressChart from "./ProgressChart";
import DialogDeletePoll from "./dialogDeletePoll";
import DialogAddPoll from "./dialogAddPoll";
import LoadingIcon from "../../../shared/general/loadingIcon";

const StyledCard = styled(Card)({
  borderRadius: 16,
  marginBottom: 40,
  width: "100%",
});

const StyledDivider = styled(Divider)({
  width: "100%",
  margin: "10px 0",
});

const WrapStatus = styled("div")({
  display: "flex",
  alignItems: "center",
});

const AddMissionButton = styled(Button)({
  fontSize: 18,
  backgroundColor: "#412020",
  "&:hover": {
    backgroundColor: "#5B3737",
  },
});

const StyledRoot = styled("div")({
  minHeight: "100dvh",
  backgroundSize: "cover",
  backgroundImage: `url(${BgPage})`,
});

const Poll = () => {
  const dispatch = useDispatch();
  const { result: poll, isFetching } = useSelector((state) => state.poll);

  const [open, setOpen] = useState(false);
  const [openDialogClose, setOpenDialogClose] = useState(false);
  const [idPollSelected, setIdPollSelected] = useState(null);
  const [displayClose, setDisplayClose] = useState(null);
  const [dateFiiter, setDateFiiter] = useState(dayjs());
  const [value, setValue] = useState("process");

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseDialogClose = () => {
    setOpenDialogClose(false);
  };

  const filterData = poll?.filter((item) => {
    if (value === "end") {
      return (
        dayjs().isAfter(item.endDate) && dateFiiter.isSame(item.endDate, "year")
      );
    } else {
      return (
        dayjs().isBefore(item.endDate) &&
        dateFiiter.isSame(item.endDate, "year")
      );
    }
  });

  useEffect(() => {
    dispatch(getAllPolls());
  }, []);

  return (
    <StyledRoot className={"page"}>
      <HeaderPage textLabel={"แบบสำรวจ"} color={"#000000"} />
      <StyledDivider />
      <Container>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            gap: "8px",
            marginBottom: "16px",
          }}
        >
          <FormControl sx={{ minWidth: "160px" }}>
            <InputLabel>สถานะ</InputLabel>
            <Select label="สถานะ" value={value} onChange={handleChange}>
              <MenuItem value={"process"}>{"กำลังดำเนินการ"}</MenuItem>
              <MenuItem value={"end"}>{"สิ้นสุดแล้ว"}</MenuItem>
            </Select>
          </FormControl>
          <DatePicker
            views={["year"]}
            label="เลือกปีที่สิ้นสุด"
            value={dateFiiter}
            onChange={(newValue) => {
              setDateFiiter(newValue);
            }}
            minDate={dayjs().subtract(1, "year")}
            maxDate={dayjs()}
          />
          <AddMissionButton
            variant="contained"
            startIcon={<AddIcon />}
            onClick={handleClickOpen}
          >
            {"เพิ่มแบบสำรวจ"}
          </AddMissionButton>
        </div>
        {isFetching ? (
          <LoadingIcon />
        ) : filterData && filterData.length > 0 ? (
          filterData.map((value) => (
            <StyledCard variant="outlined" key={value.idPoll}>
              <CardContent>
                <Typography variant="h4" align="center" gutterBottom>
                  {value.poll}
                </Typography>
                <Grid container spacing={4} alignItems="center">
                  <Grid item xs={12}>
                    <ProgressChart
                      chartData={value.questionList}
                      sumScore={value.voted}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Stack
                      direction="row"
                      alignItems={"center"}
                      justifyContent={"space-around"}
                    >
                      <div>
                        <Typography color="textSecondary">
                          {`${"สิ้นสุด"} ${dayjs(value.endDate).format(
                            "D MMM YYYY "
                          )}`}
                        </Typography>
                        {dayjs().isAfter(dayjs(value.endDate)) && (
                          <Chip
                            label="สิ้นสุดวันโหวตแล้ว"
                            color="error"
                            size="small"
                          />
                        )}
                      </div>
                      <WrapStatus>
                        <Typography color="textSecondary" marginRight={1}>
                          {"สถานะ"}
                        </Typography>
                        {value.status === "open" ? (
                          <Chip label={"เปิด"} color="success" />
                        ) : (
                          <Chip label={"ปิด"} color="error" />
                        )}
                      </WrapStatus>
                      <Chip
                        color="secondary"
                        label={`Vote ${value.voted.toFixed(2)}%`}
                        icon={<HowToVoteIcon />}
                      />
                      {value.status === "open" && (
                        <Button
                          variant="contained"
                          color="error"
                          onClick={() => {
                            setOpenDialogClose(true);
                            setIdPollSelected(value.idPoll);
                            setDisplayClose(value.poll);
                          }}
                        >
                          {"ปิดแบบสำรวจ"}
                        </Button>
                      )}
                    </Stack>
                  </Grid>
                </Grid>
              </CardContent>
            </StyledCard>
          ))
        ) : (
          <Typography variant="h5" textAlign={"center"}>
            {"ไม่มีข้อมูล"}
          </Typography>
        )}
      </Container>
      <DialogAddPoll open={open} handleClose={handleClose} />
      <DialogDeletePoll
        idPoll={idPollSelected}
        displayClose={displayClose}
        open={openDialogClose}
        onClose={handleCloseDialogClose}
      />
    </StyledRoot>
  );
};

export default Poll;
