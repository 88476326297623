import React from "react";
import { Avatar, Box, Button, Container, Dialog, DialogActions, DialogTitle, Paper, Popper, TextField, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import dayjs from "dayjs";
import monthOnlyOperators from "../../shared/dataGridFilter/monthOnlyOperators";

function isOverflown(element) {
  return (
    element.scrollHeight > element.clientHeight ||
    element.scrollWidth > element.clientWidth
  );
}

const GridCellExpand = React.memo(function GridCellExpand(props) {
  const { width, value } = props;
  const wrapper = React.useRef(null);
  const cellDiv = React.useRef(null);
  const cellValue = React.useRef(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showFullCell, setShowFullCell] = React.useState(false);
  const [showPopper, setShowPopper] = React.useState(false);

  const handleMouseEnter = () => {
    const isCurrentlyOverflown = isOverflown(cellValue.current);
    setShowPopper(isCurrentlyOverflown);
    setAnchorEl(cellDiv.current);
    setShowFullCell(true);
  };

  const handleMouseLeave = () => {
    setShowFullCell(false);
  };

  React.useEffect(() => {
    if (!showFullCell) {
      return undefined;
    }

    function handleKeyDown(nativeEvent) {
      // IE11, Edge (prior to using Bink?) use 'Esc'
      if (nativeEvent.key === 'Escape' || nativeEvent.key === 'Esc') {
        setShowFullCell(false);
      }
    }

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [setShowFullCell, showFullCell]);

  return (
    <Box
      ref={wrapper}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      sx={{
        alignItems: 'center',
        lineHeight: '24px',
        width: '100%',
        height: '100%',
        position: 'relative',
        display: 'flex',
      }}
    >
      <Box
        ref={cellDiv}
        sx={{
          height: '100%',
          width,
          display: 'block',
          position: 'absolute',
          top: 0,
        }}
      />
      <Box
        ref={cellValue}
        sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
      >
        {value}
      </Box>
      {showPopper && (
        <Popper
          open={showFullCell && anchorEl !== null}
          anchorEl={anchorEl}
          style={{ 
            width, 
            // marginLeft: -17 
          }}
        >
          <Paper
            elevation={1}
            style={{ minHeight: wrapper.current.offsetHeight - 3 }}
          >
            <Typography variant="body2" style={{ padding: 8 }}>
              {value}
            </Typography>
          </Paper>
        </Popper>
      )}
    </Box>
  );
});

function renderCellExpand(params) {
  return (
    <GridCellExpand value={params.value || ''} width={params.colDef.computedWidth} />
  );
}

const CustomToolbar = ({ setFilterButtonEl }) => (
  <GridToolbarContainer>
    <GridToolbarColumnsButton />
    <GridToolbarFilterButton ref={setFilterButtonEl} />
    {/* <GridToolbarFilterButton 
      // sx={{ '& .MuiMenu-paper': { flexDirection: isMobile ? 'column' : 'row' } }}
      sx={{ '& .MuiMenu-paper': { flexDirection: 'column' } }}
    /> */}
  </GridToolbarContainer>
);

const CompanySendCoinGrid = ({ data, deleteSendCoin }) => {
  const [filterButtonEl, setFilterButtonEl] = React.useState(null);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [rowData, setRowData] = React.useState(null);

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleOpenDelete = (row) => {
    setOpenDelete(true);
    setRowData(row);
  };

  const handleConfirmDelete = () => {
    deleteSendCoin(rowData.idCompanySendCoin);
  };

  const columns = [
    // { field: "senderFullName", headerName: "ชื่อผู้ส่ง", flex: 2 },
    {
      field: "receiverFullName",
      headerName: "ชื่อผู้รับ",
      sortable: false,
      minWidth: 240,
      renderCell: (params) => (
        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
          <Avatar  alt={"userImage"} src={params.row.imageURL} />
          <Typography>{`${params.row.receiver_firstname_TH} ${params.row.receiver_lastname_TH}`}</Typography>
        </div>
      ),
    },
    { field: "divisionName", headerName: "ฝ่าย", sortable: false, width: 120 },
    { field: "departmentName", headerName: "ส่วน", sortable: false, width: 120 },
    { field: "sectionName", headerName: "แผนก", sortable: false, width: 120 },
    { field: "positionName", headerName: "ตำแหน่ง", sortable: false, width: 120 },
    {
      field: "sendDate",
      headerName: "วันที่ส่ง",
      type: 'dateTime',
      filterOperators: monthOnlyOperators,
      width: 140,
      valueFormatter: (params) => {
        const date = dayjs(params.value).format('DD/MM/YYYY HH:mm');
        return date;
      } 
    },
    { field: "coinName", headerName: "เหรียญ", width: 100, sortable: false },
    { field: "amount", headerName: "จำนวน", type: 'number', width: 80 },
    {
      field: "detail",
      headerName: "รายละเอียด",
      width: 160,
      sortable: false,
      renderCell: renderCellExpand,
    },
    {
      field: 'actions',
      headerName: "จัดการ",
      type: 'actions',
      width: 80,
      getActions: (params) => [
        <Button
          size="small"
          color="error"
          variant="outlined"
          onClick={() => handleOpenDelete(params.row)}
        >
          {"ยกเลิก"}
        </Button>,
      ],
    },
  ];

  return (
    <>
      <div style={{ maxHeight: 600 }}>
        <DataGrid
          getRowId={(row) => row.idCompanySendCoin}
          rows={data}
          columns={columns}
          rowHeight={64}
          hideFooterSelectedRowCount
          disableRowSelectionOnClick
        />
      </div>
      <Dialog fullWidth open={openDelete} onClose={handleCloseDelete}>
        <DialogTitle>
          ยกเลิกรายการ
        </DialogTitle>
        <DialogActions>
          <Button color="success" variant="contained" onClick={handleConfirmDelete}>
            ยืนยัน
          </Button>
          <Button color="error" variant="outlined" onClick={handleCloseDelete}>
            ยกเลิก
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CompanySendCoinGrid;
