import React from 'react';
import { Grid, CardActionArea,Stack, Typography, Avatar, Chip, Dialog, DialogContent } from '@mui/material';
import dayjs from 'dayjs';
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";
import { styled } from "@mui/material/styles";
import FormSubmitMission from './formSubmitMission';
import { useState } from "react";
import { MainCoin } from '../../../shared/typeCoin';
const StyledCard = styled(Card)({
  width: "100%",
  height: "100%",
  borderRadius: 16,
  // position: "relative",

});

const StyledTitle = styled(Typography)({
  fontSize: "24px",
});

const StyledCardContent = styled(CardContent)({
  color: "#FFFFFF",
  display: "flex",
  width: "100%",
  height: "100%",
  borderRadius: 16,
  flexDirection: "column",
  justifyContent: "space-between",
  padding: "24px"

});
const WrapStatus = styled("div")({
  display: "flex",
  alignItems: "center",
  paddingTop: "10px",
  "& div": {
    margin: 8,
  },
});

const MyMissionCard = (props) => {
  const { value, setCurrentMission, setSnackBarConfig, setModeSnackbar, setDisplaySnackbar } = props
  const [openFormSubmit, setOpenFormSubmit] = useState(false);
  const colorStatus = ["warning", "warning", "secondary", "success", "error"];
  const statusName = ["กำลังดำเนินการ", "ส่งแล้ว รออนุมัติ", "อนุมัติ", "ยกเลิก", "ไม่อนุมัติ"]
  return (
    <Grid item xs={12} sm={6} md={6} xl={4}>
      <StyledCard>
        <CardActionArea sx={{ height: "100%" }} onClick={() => {
          if (value.idMissionStatus === 1) {
            setOpenFormSubmit(true);
          } else {
            setCurrentMission(value)
          }
        }}>
          <StyledCardContent>
            <div>
              <StyledTitle gutterBottom>
                <FormatQuoteIcon
                  fontSize={"large"}
                  style={{ transform: "scale(-1, 1)" }}
                />
                {value.missionTitle}
                <FormatQuoteIcon fontSize={"large"} />
                <Typography fontSize={"16px"} gutterBottom>
                  {value.missionDetail}
                </Typography>
              </StyledTitle>
              <WrapStatus>
                <Typography
                  mt={2}
                  fontSize={"16px"}
                  color="textSecondary"
                  gutterBottom
                >
                  {"สถานะ:"}
                </Typography>
                <Chip
                  label={statusName[value.idMissionStatus - 1]}
                  color={colorStatus[value.idMissionStatus]}
                />
              </WrapStatus>

              <div style={{ display: "flex", alignItems: "center", gap: "10px", paddingTop: "10px" }}>
                <Typography fontSize={"16px"} color="textSecondary">
                  {"วันสิ้นสุด:"}
                </Typography>
                <Typography fontSize={"16px"} gutterBottom>
                  {dayjs(value.missionDate).format("D MMM YYYY ")}
                </Typography>
              </div>
              {
                value.submitDate && (
                  <div style={{ display: "flex", alignItems: "center", gap: "10px", paddingTop: "10px" }}>
                    <Typography fontSize={"16px"} color="textSecondary">
                      {"ส่งเมื่อ:"}
                    </Typography>
                    <Typography fontSize={"16px"} gutterBottom>
                      {dayjs(value.submitDate).format("D MMM YYYY ")}
                    </Typography>
                  </div>
                )
              }
              <Stack direction="row" spacing={2} alignItems={"center"} marginBottom={"20px"}
                sx={{ justifyContent: { xs: "center", md: "flex-start" } }}
              >
                <Typography fontSize={"16px"}  color="textSecondary">
                      {"ได้รับ:"}
                    </Typography>
                <MainCoin width={32} />
                <Typography className="field-label" gutterBottom>
                  x{value.reward}
                </Typography>
              </Stack>
            </div>
            <div style={{ display: "flex", alignItems: "center", gap: "10px", paddingTop: "10px" }}>
              <Typography fontSize={"16px"} color="textSecondary">
                {"ผู้มอบหมาย:"}
              </Typography>
              <div style={{ display: "flex", gap: "8px", alignItems: "center" }}>
                <Avatar alt="employee" src={value.assigner_imageURL} />
                <Typography gutterBottom>
                  {`${value.assigner_firstname_TH} ${value.assigner_lastname_TH}`}
                </Typography>
              </div>
            </div>
          </StyledCardContent>
        </CardActionArea>
      </StyledCard>
      <Dialog
        open={openFormSubmit}
        maxWidth={"lg"}
        fullWidth={true}
        onClose={() => {
          setOpenFormSubmit(false);
        }}
      >
        <DialogContent>
          <FormSubmitMission handleClose={
            () => {
              setOpenFormSubmit(false);
            }
          }
            setSnackBarConfig={setSnackBarConfig}
            setModeSnackbar={setModeSnackbar}
            setDisplaySnackbar={setDisplaySnackbar}
            idMission={value.idMission}

          />
        </DialogContent>
      </Dialog>
    </Grid>
  );
};

export default MyMissionCard;
