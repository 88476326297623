import React from "react";
import {
  Box,
} from "@mui/material";
//Translator TH-EN
import TableCustom from "../../shared/tableCustom";

const TableBooking = (props) =>{
    const { columns, rows} = props;
    return (
        <Box>
          <TableCustom
            columns = {columns}
            rows = {rows?? []}
          />
        </Box>
    )
}

export default TableBooking;
