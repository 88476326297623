import React from "react";
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import InputField from "../../shared/inputForm/inputField";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "sonner";
import { addFeedback, getFeedback } from "../../../../actions/feedback";

const DialogSendFeedback = ({ open, handleClose, setTabValue }) => {
  const dispatch = useDispatch();
  const { result: employees } = useSelector((state) => state.users);
  const { result: userProfile } = useSelector((state) => state.userProfile);

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: {
      selectedEmployee: null,
      feedback: "",
      feedbackType: "identify",
    },
  });

  const handleAddFeedback = async (values) => {
    const res = await dispatch(
      addFeedback({
        idReceiver: values.selectedEmployee.idEmployees,
        feedback: values.feedback || null,
        feedbackType: values.feedbackType,
      })
    );
    if (res && res.status === 201) {
      dispatch(getFeedback());
      handleClose();
      setTabValue(1);
      toast.success("สำเร็จ");
    } else {
      toast.error("เกิดข้อผิดพลาด");
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <DialogTitle>{"ส่งฟีดแบค"}</DialogTitle>
      <form onSubmit={handleSubmit(handleAddFeedback)}>
        <DialogContent>
          <Stack gap={2}>
            {employees && (
              <Controller
                control={control}
                name="selectedEmployee"
                render={({ field, fieldState }) => (
                  <Autocomplete
                    {...field}
                    onChange={(event, value) => field.onChange(value)}
                    options={employees.filter(
                      (item) => item.idEmployees !== userProfile.idEmployees
                    )}
                    getOptionLabel={(option) =>
                      `${option.firstname_TH} ${option.lastname_TH}`
                    }
                    isOptionEqualToValue={(option, value) =>
                      option.idEmployees === value.idEmployees
                    }
                    renderOption={(props, option) => (
                      <Box component="li" {...props} key={option.idEmployees}>
                        {`${option.firstname_TH} ${option.lastname_TH}`}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        label="พนักงาน"
                        error={Boolean(fieldState.error)}
                        helperText={
                          fieldState.error && fieldState.error.message
                        }
                      />
                    )}
                  />
                )}
              />
            )}
            <InputField
              control={control}
              name={"feedback"}
              multiline
              rows={5}
              fullWidth
              label="ฟีดแบค"
              inputProps={{ maxLength: 200 }}
            />
            <Controller
              control={control}
              name="feedbackType"
              render={({ field, fieldState }) => (
                <FormControl fullWidth>
                  <FormLabel>{"รูปแบบการส่ง"}</FormLabel>
                  <RadioGroup row {...field}>
                    <FormControlLabel
                      value="identify"
                      control={<Radio />}
                      label={"ระบุตัวตัว"}
                    />
                    <FormControlLabel
                      value="anonymous"
                      control={<Radio />}
                      label={"ไม่ระบุตัวตน"}
                    />
                  </RadioGroup>
                </FormControl>
              )}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button color="error" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            type="submit"
            variant="contained"
            disabled={isSubmitting}
          >
            Add
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default DialogSendFeedback;
