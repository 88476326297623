import React from "react";
import { styled } from "@mui/material/styles";
import { Avatar, keyframes } from "@mui/material";

import VeryBadIcon from "./assets/very_bad.png";
import BadIcon from "./assets/bad.png";
import LittleBadIcon from "./assets/little_bad.png";
import LittleHappyIcon from "./assets/little_happy.png";
import HappyIcon from "./assets/happy.png";
import VeryHappyIcon from "./assets/very_happy.png";

const pulseVeryBad = keyframes`
  0% {
    boxShadow: 0 0 0 0 rgba(255,0,29, 0.4);
  }
  70% {
    boxShadow: 0 0 0 12px rgba(236,201,81, 0);
  }
  100% {
    boxShadow: 0 0 0 0 rgba(236,201,81, 0);
  }
`;
const pulseBad = keyframes`
  0% {
    boxShadow: 0 0 0 0 rgba(255,183,0, 0.4);
  }
  70% {
    boxShadow: 0 0 0 12px rgba(255,0,29, 0);
  }
  100% {
    boxShadow: 0 0 0 0 rgba(255,0,29, 0);
  }
`;
const pulseLittleBad = keyframes`
  0% {
    boxShadow: 0 0 0 0 rgba(255,183,0, 0.4);
  }
  70% {
    boxShadow: 0 0 0 12px rgba(255,0,29, 0);
  }
  100% {
    boxShadow: 0 0 0 0 rgba(255,0,29, 0);
  }
`;
const pulseLittleHappy = keyframes`
  0% {
    boxShadow: 0 0 0 0 rgba(255,183,0, 0.4);
  }
  70% {
    boxShadow: 0 0 0 12px rgba(255,0,29, 0);
  }
  100% {
    boxShadow: 0 0 0 0 rgba(255,0,29, 0);
  }
`;
const pulseHappy = keyframes`
  0% {
    boxShadow: 0 0 0 0 rgba(131,196,0, 0.4);
  },
  70% {
    boxShadow: 0 0 0 12px rgba(0,202,70, 0);
  },
  100% {
    boxShadow: 0 0 0 0 rgba(0,202,70, 0);
  },
`;
const pulseVeryHappy = keyframes`
  0% {
    boxShadow: 0 0 0 0 rgba(0,184,30, 0.4);
  }
  70% {
    boxShadow: 0 0 0 12px rgba(0,202,70, 0);
  },
  100% {
    boxShadow: 0 0 0 0 rgba(0,202,70, 0);
  }
`;

const StyledAvatar = styled(Avatar, {
  shouldForwardProp: (prop) => prop !== "feeling" && prop !== "unSelected",
})(({ feeling, unSelected }) => {
  let choice = {
    width: "100%",
    height: "100%",
    "&:hover": {
      cursor: "pointer",
    },
    // "&.choice-unselected": {
    //   opacity: 0.1,
    // },
  };
  if (unSelected) {
    choice = {
      ...choice,
      opacity: 0.4,
    };
  }
  switch (feeling) {
    case "verybad":
      return {
        ...choice,
        "&:hover": {
          animation: `${pulseVeryBad} 1s ease-in-out infinite`,
        },
      };
    case "bad":
      return {
        ...choice,
        "&:hover": {
          animation: `${pulseBad} 1s ease-in-out infinite`,
        },
      };
    case "littlebad":
      return {
        ...choice,
        "&:hover": {
          animation: `${pulseLittleBad} 1s ease-in-out infinite`,
        },
      };
    case "littlehappy":
      return {
        ...choice,
        "&:hover": {
          animation: `${pulseLittleHappy} 1s ease-in-out infinite`,
        },
      };
    case "happy":
      return {
        ...choice,
        "&:hover": {
          animation: `${pulseHappy} 1s ease-in-out infinite`,
        },
      };
    case "veryhappy":
      return {
        ...choice,
        "&:hover": {
          animation: `${pulseVeryHappy} 1s ease-in-out infinite`,
        },
      };
    default:
      return choice;
  }
});

const icon = {
  verybad: VeryBadIcon,
  bad: BadIcon,
  littlebad: LittleBadIcon,
  littlehappy: LittleHappyIcon,
  happy: HappyIcon,
  veryhappy: VeryHappyIcon,
};

const FeelingIcon = (props) => {
  const { unSelected, feeling, handleClick } = props;
  return (
    <StyledAvatar
      alt={feeling}
      src={icon[feeling]}
      onClick={handleClick}
      feeling={feeling}
      unSelected={unSelected}
      // className={clsx(classes.choice, {
      //   [`choice-unselected`]: unSelected,
      //   [classes.choiceVeryBad]: feeling == "verybad",
      //   [classes.choiceBad]: feeling == "bad",
      //   [classes.choiceLittleBad]: feeling == "littlebad",
      //   [classes.choiceLittleHappy]: feeling == "littlehappy",
      //   [classes.choiceHappy]: feeling == "happy",
      //   [classes.choiceVeryHappy]: feeling == "veryhappy",
      // })}
    />
  );
};

export default FeelingIcon;
