import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Button,
  Divider,
  Grid,
  Typography,
  Autocomplete,
  TextField,
  CircularProgress,
  Container,
  Tabs,
  Tab,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { useOrganization } from "../../../../quries/company";
import DailyDetail from "./DailyDetail";
import { useNavigate } from "react-router-dom";

const StyledDatePicker = styled(DatePicker)({
  backgroundColor: "#282828",
  "& .MuiOutlinedInput-root": {
    color: "#ffffff",
    "& fieldset": {
      borderColor: "#ffffff",
    },
    "&:hover fieldset": {
      borderColor: "#ffffff",
    },
    "& .Mui-focused fieldset": {
      borderColor: "#ffffff",
    },
  },
  "& .MuiIconButton-root": {
    color: "#ffffff",
  },
  "& .MuiInputLabel-root": {
    color: "#ffffff",
  },
});

const StyledDivider = styled(Divider)({
  marginTop: 16,
  marginBottom: 16,
  backgroundColor: "#ffffff",
});

const StyledAutocomplete = styled(Autocomplete)({
  backgroundColor: "#282828",
  "& .MuiOutlinedInput-root": {
    color: "#ffffff",
  },
  fieldset: {
    borderColor: "#ffffff",
  },
  "& .MuiFormLabel-root,& .MuiFormLabel-root.Mui-focused": {
    color: "#ffffff",
  },
});

const MoraleDailyDashboard = () => {
  const [companySelected, setCompanySelected] = useState(null);
  const [departmentSelected, setDepartmentSelected] = useState({
    departmentName: "All Department",
    idDepartment: 0,
  });
  const [sectionSelected, setSectionSelected] = useState({
    sectionName: "All Section",
    idSection: 0,
  });
  const [filterDate, setFilterDate] = useState(dayjs());

  const [tabValue, setTabValue] = useState(0); // State for selected tab

  const organization = useOrganization();

  useEffect(() => {
    if (organization.isSuccess) {
      setCompanySelected(organization.data[0]);
    }
  }, [organization.isSuccess]);

  if (organization.isPending) {
    return <CircularProgress />;
  }

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const maxDate = dayjs().add(1, "year").endOf("year");

  return (
    <div
      className={`page dashboard-page`}
      style={{ backgroundColor: "#1a1a1a", minHeight: "100vh" }}
    >
      <Grid container alignItems="center" spacing={2}>
        <Grid item>
          <Typography variant="h4" color={"#ffffff"}>
            สุขภาพใจรายวัน
          </Typography>
        </Grid>
        {/* <Grid item>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            indicatorColor="secondary"
            sx={{
              "& .MuiTabs-indicator": {
                backgroundColor: "white",
              },
            }}
          >
            <Tab label="บริษัท" sx={{ color: "white" }} />
            <Tab label="หน่วยงาน" sx={{ color: "white" }} />
            <Tab label="บุคคล" sx={{ color: "white" }} />
          </Tabs>
        </Grid> */}
      </Grid>
      <StyledDivider />
      <Container>
        <Grid container spacing={2} marginBottom={4}>
          <Grid item xs={12} sm={6} md={3}>
            <StyledDatePicker
              value={filterDate}
              onChange={(newValue) => setFilterDate(newValue)}
              label={"เลือกเดือนและปี"}
              views={["year", "month"]}
              minDate={dayjs().startOf("year")}
              maxDate={maxDate}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <StyledAutocomplete
              disableClearable
              value={companySelected}
              options={organization.data}
              onChange={(event, newValue) => {
                setCompanySelected(newValue);
                setDepartmentSelected({
                  departmentName: "All Department",
                  idDepartment: 0,
                });
                setSectionSelected({
                  sectionName: "All Section",
                  idSection: 0,
                });
              }}
              getOptionLabel={(option) => option.companyName}
              isOptionEqualToValue={(option, value) =>
                option.idCompany === value.idCompany
              }
              renderInput={(params) => (
                <TextField {...params} label="Company" />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <StyledAutocomplete
              disableClearable
              value={departmentSelected}
              options={
                companySelected
                  ? [
                      { departmentName: "All Department", idDepartment: 0 },
                      ...companySelected.department,
                    ]
                  : []
              }
              onChange={(event, newValue) => setDepartmentSelected(newValue)}
              getOptionLabel={(option) => option.departmentName}
              isOptionEqualToValue={(option, value) =>
                option.idDepartment === value.idDepartment
              }
              renderInput={(params) => (
                <TextField {...params} label="Department" />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <StyledAutocomplete
              disableClearable
              value={sectionSelected}
              options={
                companySelected
                  ? [
                      { sectionName: "All Section", idSection: 0 },
                      ...companySelected.section,
                    ]
                  : []
              }
              onChange={(event, newValue) => setSectionSelected(newValue)}
              getOptionLabel={(option) => option.sectionName}
              isOptionEqualToValue={(option, value) =>
                option.idSection === value.idSection
              }
              renderInput={(params) => (
                <TextField {...params} label="Section" />
              )}
            />
          </Grid>
        </Grid>
        {!companySelected?.idCompany && (
          <Typography variant="h5" color={"#ffffff"}>
            {"เลือกบริษัท"}
          </Typography>
        )}
        {companySelected?.idCompany && (
          <DailyDetail
            companySelected={companySelected}
            idDepartment={departmentSelected?.idDepartment}
            idSection={sectionSelected?.idSection}
            date={filterDate}
          />
        )}
      </Container>
    </div>
  );
};

export default MoraleDailyDashboard;
