import React from "react";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Container,
  FormControl,
  FormHelperText,
  Grid,
  ImageList,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import UploadRewardImage from "../../../shared/uploadReward/uploadRewardImage";
import VariationField from "../../../shared/formReward/variationField";
import SwitchInput from "../../../shared/inputForm/switchInput";
import HeaderPage from "../../../shared/header/headerPage";
import { StyledDividerHeader } from "../../../shared/dividerHeader";
import TipTap from "../../../shared/tiptapEditor";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useAddUniReward } from "../../../../../quries/uniReward";
import { toast } from "sonner";
import InputField from "../../../shared/inputForm/inputField";
import { useRewardType } from "../../../../../quries/reward";

const StyledRoot = styled("div")({
  display: "flex",
  flexDirection: "column",
  padding: 15,
  marginTop: 60,
  margin: "0px auto",
  minWidth: 350,
});

const StyledCard = styled(Card)({
  borderRadius: 8,
});

function CreateUniReward() {
  const navigate = useNavigate();
  const { data: rewardType } = useRewardType();
  const { mutate } = useAddUniReward();

  const defaultValues = {
    name: "",
    detail: "",
    idRewardType: "",
    price: 0,
    quantity: 0,
    sku: "",
    images: [null, null, null, null],
    mainImage: { image: '' },
    openVariation: false,
    addOptionImage: false,
    variation: [{ name: "", options: [{ name: "", image: null }] }],
    attributes: [{ option1: "", option2: "", price: "", quantity: "", sku: "" }],
  };

  const validationSchema = yup.object().shape({
    name: yup.string().max(100).required('required'),
    idRewardType: yup.string().required('required'),
    price: yup.number().when("openVariation", {
      is: false,
      then: (schema) => schema.min(1).max(100000).integer().required('required'),
    }),
    quantity: yup.number().when("openVariation", {
      is: false,
      then: (schema) => schema.min(1).max(1000).integer().required('required'),
    }),
    sku: yup.string().when("openVariation", {
      is: false,
      then: (schema) => schema.max(15).required('required'),
    }),
    mainImage: yup
      .mixed()
      .test(
        "required",
        "โปรดเพิ่มรูปภาพหลัก",
        (value) => value && (value.path || value.image)
    ),
    images: yup.array().max(4),
    openVariation: yup.boolean(),
    variation: yup.array().when("openVariation", {
      is: true,
      then: (schema) =>
        schema.of(
          yup.object().shape({
            name: yup.string().max(15).required('required'),
            options: yup.array().of(
              yup.object().shape({
                name: yup.string().max(20).required('required'),
                image: yup.mixed(),
              })
            ),
          })
        ),
    }),
    attributes: yup.array().when("openVariation", {
      is: true,
      then: (schema) => schema.of(
        yup.object().shape({
          price: yup
            .number()
            .transform((value) => (isNaN(value) ? null : value))
            .nullable()
            .min(1, "must be greater than or equal to 1")
            .integer()
            .required('required'),
          quantity: yup
            .number()
            .transform((value) => (isNaN(value) ? null : value))
            .nullable()
            .min(1, "must be greater than or equal to 1")
            .integer()
            .required('required'),
          sku: yup.string().max(15, "must be at most 15").required('required'),
        })
      )
    }),
    addOptionImage: yup.boolean(),
  });

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
    getValues,
  } = useForm({
    defaultValues: defaultValues,
    resolver: yupResolver(validationSchema),
  });

  const images = getValues("images");

  const handleAddUniReward = async (data) => {
    console.log(data)
    const formData = new FormData();
    for (const property in data) {
      if (property === "images") {
        data[property]
          .filter((item) => item instanceof File)
          .forEach((file) => formData.append(property, file));
      } else if (Array.isArray(data[property])) {
        formData.append(property, JSON.stringify(data[property]));
      } else {
        formData.append(property, data[property]);
      }
    }

    data["variation"][0].options
      // .filter((item) => item.image instanceof File)
      .forEach((file) => formData.append("variationImages", file.image));

   
    // // for (var pair of formData.entries()) {
    // //   console.log(pair[0] + ", " + pair[1]);
    // // }
    
    // // const res = await dispatch(addUniReward(formData));

    mutate(formData, {
      onSuccess: () => toast.success("สำเร็จ"),
      onError: () => toast.error("เกิดข้อผิดพลาด"),
      onSettled: () => navigate("/uni-team/reward"),
    })
  };

  return (
    <StyledRoot className={"page"}>
      <HeaderPage textLabel={"เพิ่มสินค้า"} />
      <StyledDividerHeader />
      <Container>
        <form onSubmit={handleSubmit(handleAddUniReward)}>
          <Stack spacing={2}>
            <StyledCard elevation={4}>
              <CardHeader title="ข้อมูลสินค้า" />
              <CardContent>
                <Grid container spacing={2} marginTop={0}>
                  <Grid item xs={12} sm={8}>
                    <Controller
                      control={control}
                      name="name"
                      label="ชื่อสินค้า"
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Controller
                      name="idRewardType"
                      control={control}
                      render={({ field, fieldState }) => (
                        <FormControl fullWidth>
                          <InputLabel>ประเภทของรางวัล</InputLabel>
                          <Select
                            {...field}
                            label="ประเภทของรางวัล"
                            error={Boolean(fieldState.error)}
                          >
                            {rewardType?.map((item) => (
                              <MenuItem value={item.idRewardType}>
                                {item.rewardType}
                              </MenuItem>
                            ))}
                          </Select>
                          {Boolean(fieldState.error) && (
                            <FormHelperText error>
                              {fieldState?.error?.message}
                            </FormHelperText>
                          )}
                        </FormControl>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <InputLabel>รายละเอียด</InputLabel>
                    <Controller
                      name="detail"
                      control={control}
                      render={({ field }) => (
                        <TipTap
                          detail={field.value}
                          onChange={field.onChange}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Controller
                      control={control}
                      name="price"
                      label="ราคา"
                      type="number"
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Controller
                      control={control}
                      name="quantity"
                      label="จำนวน"
                      type="number"
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Controller
                      control={control}
                      name="sku"
                      label="SKU"
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </StyledCard>

            <StyledCard elevation={4}>
              <CardHeader title={"รูปภาพสินค้า"} />
              <CardContent>
                <Typography>{"ขนาดไฟล์ภาพต้องไม่เกิน 500 KB"}</Typography>
                <ImageList cols={5}>
                  <Grid item xs={2}>
                    <Controller
                      name={'mainImage'}
                      control={control}
                      render={({ field }) => (
                        <UploadRewardImage
                          {...field}
                          acceptFileType={"image/jpeg, image/png, image/webp"}
                          error={errors.mainImage}
                          title={"รูปภาพหลัก"}
                        />
                      )}
                    />
                  </Grid>
                  {images.map((value, index) => (
                    <Grid item xs={2} key={index}>
                      <Controller
                        name={`images.${index}`}
                        control={control}
                        render={({ field }) => (
                          <UploadRewardImage
                            {...field}
                            acceptFileType={"image/jpeg, image/png, image/webp"}
                            error={errors.images}
                            title={"รูปภาพเสริม"}
                          />
                        )}
                      />
                    </Grid>
                  ))}
                </ImageList>
              </CardContent>
            </StyledCard>
            <StyledCard>
              <CardHeader
                title={"ข้อมูลการขาย"}
                action={
                  <SwitchInput
                    name="openVariation"
                    control={control}
                    label={"เพิ่มตัวเลือกสินค้า"}
                  />
                }
              />
              {watch("openVariation") && (
                <CardContent>
                  <VariationField {...{ control, errors }} />
                </CardContent>
              )}
            </StyledCard>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button type="submit" variant="contained">
                {"ยืนยัน"}
              </Button>
            </div>
          </Stack>
        </form>
      </Container>
    </StyledRoot>
  );
}

export default CreateUniReward;