import React, { Fragment, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Typography,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Snackbar,
  Alert,
  IconButton,
  Grid,
  Stack,
  CardActionArea,
  Container,
} from "@mui/material";
import Chip from "@mui/material/Chip";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import PlaceIcon from "@mui/icons-material/Place";
import EventIcon from "@mui/icons-material/Event";
import LabelIcon from "@mui/icons-material/Label";
import Pagination from "@mui/material/Pagination";
import PeopleIcon from "@mui/icons-material/People";
import NavigationIcon from "@mui/icons-material/Navigation";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import UpdateIcon from "@mui/icons-material/Update";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";

import { SpecialIcon } from "../../shared/typeCoin";
import DialogMyActivity from "./dialogMyActivity";
import dayjs from "dayjs";
import Help from "./assets/help.png";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteActivityRegister,
  getActivityRegister,
} from "../../../../actions/activity";
import { toast } from "sonner";
import LoadingIcon from "../../shared/general/loadingIcon";
import utils from "../../../../utils";

const StyledDivider = styled(Divider)({
  width: "100%",
  margin: "10px 0",
});

const StyledChip = styled(Chip)(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

const WrapStaus = styled("div")({
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  marginBottom: 24,
  ["@media only screen and (max-width:600px)"]: {
    flexDirection: "column",
  },
});

const StyledCard = styled(Card)({
  // width: "100%",
  height: "100%",
  // marginBottom: 24,
  // ["@media only screen and (min-width:576px)"]: {
  //   // eslint-disable-line no-useless-computed-key
  //   width: "calc((100% - 24px) / 2)",
  //   "&:nth-child(n)": {
  //     marginRight: 24,
  //   },
  //   "&:nth-child(2n)": {
  //     marginRight: 0,
  //   },
  // },
  // ["@media only screen and (min-width:768px)"]: {
  //   // eslint-disable-line no-useless-computed-key
  //   width: "calc((100% - 24px) / 2)",
  //   "&:nth-child(n)": {
  //     marginRight: 24,
  //   },
  //   "&:nth-child(2n)": {
  //     marginRight: 0,
  //   },
  // },
  // ["@media only screen and (min-width:992px)"]: {
  //   // eslint-disable-line no-useless-computed-key
  //   width: "calc((100% - 48px) / 3)",
  //   "&:nth-child(n)": {
  //     marginRight: 24,
  //   },
  //   "&:nth-child(3n)": {
  //     marginRight: 0,
  //   },
  // },
  // ["@media only screen and (min-width:1200px)"]: {
  //   width: "calc((100% - 72px) / 4)",
  //   "&:nth-child(n)": {
  //     marginRight: 24,
  //   },
  //   "&:nth-child(4n)": {
  //     marginRight: 0,
  //   },
  // },
});

const StyledCardMedia = styled(CardMedia)({
  height: 140,
});

const CircleDate = styled("div")({
  color: "#fff",
  width: 70,
  height: 70,
  lineHeight: 1,
  fontWeight: 700,
  fontSize: 30,
  paddingTop: 13,
  position: "absolute",
  borderRadius: "100%",
  backgroundColor: "#ff6500",
});

const WrapDetail = styled("div")({
  display: "flex",
  alignItems: "center",
  gap: 4,
  marginBottom: 8,
});

const StyledRoot = styled("div")({
  display: "flex",
  flexDirection: "column",
  padding: 15,
  marginTop: 60,
  margin: "0px auto",
  minWidth: 350,
});

const ChipActivityStatus = ({ status }) => {
  switch (status) {
    case "open":
      return (
        <Chip
          icon={<PlayCircleIcon />}
          color="success"
          size="small"
          label={status}
        />
      );
    case "close":
      return (
        <Chip
          icon={<RemoveCircleIcon />}
          color="error"
          size="small"
          label={status}
        />
      );
    case "update":
      return (
        <Chip
          icon={<UpdateIcon />}
          color="warning"
          size="small"
          label={status}
        />
      );
    case "cancel":
      return (
        <Chip icon={<CloseIcon />} color="error" size="small" label={status} />
      );
    case "rewarded":
      return (
        <Chip icon={<DoneIcon />} color="info" size="small" label={status} />
      );
    default:
      return (
        <Chip
          icon={<CloseIcon />}
          color="default"
          size="small"
          label={"no status"}
        />
      );
  }
};

const statusList = [
  {
    id: 1,
    label: "ทั้งหมด",
    value: "all",
  },
  {
    id: 2,
    label: "กำลังมาถึง",
    value: "soon",
  },
  {
    id: 3,
    label: "สิ้นสุดแล้ว",
    value: "end",
  },
  {
    id: 4,
    label: "ได้รับเหรียญแล้ว",
    value: "rewarded",
  },
  {
    id: 5,
    label: "กิจกรรมที่ยกเลิก",
    value: "cancel",
  },
];

const MyActivity = () => {
  const [openDetail, setOpenDetail] = useState(false);
  const [selectActivity, setSelectActivity] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);
  const [activitySelected, setActivitySelected] = useState(null);
  const [displayDelete, setDisplayDelete] = useState(null);
  const [selectStatus, setSelectStatus] = useState("all");
  const [activityFilter, setActivityFilter] = useState([]);

  const dispatch = useDispatch();
  const { result: activityRegister, isFetching } = useSelector(
    (state) => state.activityRegister
  );

  useEffect(() => {
    dispatch(getActivityRegister());
  }, []);

  useEffect(() => {
    if (activityRegister) {
      setActivityFilter([...activityRegister]);
    }
  }, [activityRegister]);

  const handleChangeSelectStatus = (value) => {
    let temp = [...activityRegister];
    if (value === "soon") {
      temp = activityRegister.filter((item) =>
        dayjs(item.startDate).isBetween(dayjs(), dayjs().add(30, "day"))
      );
    } else if (value === "end") {
      temp = activityRegister.filter((item) =>
        dayjs().isAfter(dayjs(item.endDate))
      );
    } else if (value === "rewarded") {
      temp = activityRegister.filter((item) => item.idActivityStatus === 5);
    } else if (value === "cancel") {
      temp = activityRegister.filter((item) => item.idActivityStatus === 4);
    }
    setActivityFilter(temp);
    setSelectStatus(value);
  };

  const handleOpenDetail = (value) => {
    setOpenDetail(true);
    setSelectActivity(value);
  };

  const handleCloseDetail = () => {
    setOpenDetail(false);
    setSelectActivity(null);
  };

  const handleOpenDelete = () => {
    setOpenDelete(true)
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleDeleteRegister = async () => {
    const res = await dispatch(deleteActivityRegister({
      idActivity: selectActivity.idActivity, 
      idEmployees: selectActivity.idEmployees
    }));
    if (res && res.status === 200) {
      dispatch(getActivityRegister());
      toast.success("ยกเลิกเข้าร่วมกิจกรรมแล้ว");
    } else {
      toast.success("เกิดข้อผิดพลาด");
    }
    handleCloseDelete();
    handleCloseDetail();
  };

  return (
    <StyledRoot className={"page"}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <img className="" alt="help" src={Help} width={45} />
        <Typography variant="h4" style={{ marginLeft: 10 }}>
          กิจกรรมของฉัน
        </Typography>
      </div>
      <StyledDivider />
      <Container>
        <WrapStaus>
          {statusList.map((data) => (
            <StyledChip
              key={data.label}
              clickable
              label={data.label}
              color={selectStatus === data.value ? "primary" : "default"}
              onClick={() => handleChangeSelectStatus(data.value)}
            />
          ))}
        </WrapStaus>
        {isFetching ? (
          <LoadingIcon />
        ) : activityRegister && activityFilter.length === 0 ? (
          <Typography variant="h5" alignSelf={"center"}>
            {"ไม่พบข้อมูล"}
          </Typography>
        ) : (
          <Grid container spacing={2} marginBottom={4}>
            {activityFilter.map((value) => (
              <Grid item xs={12} sm={6} md={4} xl={3}>
                <StyledCard fullWidth key={value.idActivity}>
                  <CardActionArea onClick={() => handleOpenDetail(value)}>
                    <StyledCardMedia
                      image={value.image}
                      title="Contemplative Reptile"
                    />
                    <CardContent
                      style={{
                        paddingTop: 35,
                        position: "relative",
                      }}
                    >
                      <div style={{ position: "absolute", top: -36, left: 20 }}>
                        <CircleDate>
                          <Typography
                            variant="h6"
                            align="center"
                            style={{ lineHeight: 1, color: "#ffffff" }}
                          >
                            {dayjs(value.startDate).format("D")}
                          </Typography>
                          <Typography
                            variant="h6"
                            align="center"
                            style={{
                              lineHeight: 1,
                              color: "#ffffff",
                              textTransform: "uppercase",
                            }}
                          >
                            {dayjs(value.startDate).format("MMM")}
                          </Typography>
                        </CircleDate>
                      </div>
                      <Typography gutterBottom variant="h6">
                        {value.name}
                      </Typography>
                      <WrapDetail>
                        <PlaceIcon color="disabled" />
                        <Typography
                          noWrap
                          variant="body2"
                          color="textSecondary"
                        >
                          {value.location}
                        </Typography>
                        {value?.coordinate?.lat && value?.coordinate?.lng && (
                          <IconButton
                            color="primary"
                            href={`https://www.google.com/maps/search/?api=1&query=${value.coordinate.lat}%2C${value.coordinate.lng}`}
                            target="_blank"
                            onClick={(event) => {
                              event.stopPropagation();
                            }}
                          >
                            <NavigationIcon />
                          </IconButton>
                        )}
                      </WrapDetail>
                      <WrapDetail>
                        <EventIcon color="disabled" />
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          gutterBottom
                          marginLeft={1}
                          marginRight={1}
                        >
                          {`เริ่ม: ${dayjs(value.startDate).format(
                            "D MMM YYYY"
                          )}`}
                        </Typography>
                        <Divider orientation="vertical" flexItem />
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          gutterBottom
                          marginLeft={1}
                        >
                          {`สิ้นสุด: ${dayjs(value.endDate).format(
                            "D MMM YYYY"
                          )}`}
                        </Typography>
                      </WrapDetail>
                      <WrapDetail>
                        <PeopleIcon color="disabled" />
                        <Typography variant="body2" color="textSecondary">
                          สถานะกิจกรรม :
                        </Typography>
                        <ChipActivityStatus status={value.status} />
                      </WrapDetail>
                      <WrapDetail>
                        <LabelIcon style={{ color: "#4caf50" }} />
                        <Typography
                          variant="body2"
                          style={{ color: "#4caf50" }}
                          gutterBottom
                          marginLeft={1}
                        >
                          {value.status === "rewarded"
                            ? "ได้รับรางวัลแล้ว"
                            : "ลงทะเบียนแล้ว"}
                        </Typography>
                      </WrapDetail>
                      <WrapDetail>
                        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                          {value?.coins.map((item, index) => (
                            <Fragment key={index}>
                              <SpecialIcon idCoinType={item.idCoinType} width={32} />
                              <Typography variant="h6" fontSize={20}>
                                {utils.numberWithCommas(item.amount)}
                              </Typography>
                              {value.coins.length > 1 &&
                                value.coins.length - 1 !== index && (
                                  <Divider
                                    orientation="vertical"
                                    flexItem
                                    sx={{ backgroundColor: "white", margin: "0 10px" }}
                                  />
                                )}
                            </Fragment>
                          ))}
                        </div>
                      </WrapDetail>
                    </CardContent>
                  </CardActionArea>
                </StyledCard>
              </Grid>
            ))}
          </Grid>
        )}
        {activityFilter.length > 0 && (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Pagination count={10} color="primary" />
          </div>
        )}
      </Container>
      {openDetail && (
        <DialogMyActivity
          open={openDetail}
          handleClose={handleCloseDetail}
          activity={selectActivity}
          handleOpenDelete={handleOpenDelete}
        />
      )}
      {openDelete && (
        <Dialog
          open={openDelete}
          fullWidth={true}
          maxWidth={"sm"}
          onClose={handleCloseDelete}
          aria-labelledby="alert-dialog-title-delete-group-name"
          aria-describedby="alert-dialog-description-delete-group-name"
        >
          <DialogTitle id="alert-dialog-title-delete-group-name">
            {"ยกเลิกเข้ากิจกรรม"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              คุณยืนยันที่จะยกเลิกเข้ากิจกรรม?
              <span style={{ color: "#f44336" }}>{selectActivity.name}</span>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDelete} color="primary">
              ยกเลิก
            </Button>
            <Button
              variant="contained"
              onClick={handleDeleteRegister}
              color="primary"
            >
              ยืนยัน
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </StyledRoot>
  );
};

export default MyActivity;
