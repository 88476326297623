import React from "react";
import { Bar } from "react-chartjs-2";
import { Typography } from "@mui/material";

const DepartmentBarChart = ({ dataDepartment }) => {
  return (
    <div>
      <Typography style={{ color: "white", marginTop: 20 }} variant="h5">
        ผลการประเมินแบ่งตามหน่วยงาน
      </Typography>
      <div
        style={{
          backgroundColor: "#424242",
          borderRadius: "8px",
          padding: "16px",
          marginTop: "16px",
        }}
      >
        <div style={{ height: 300, width: "100%" }}>
          <Bar
            data={dataDepartment}
            options={{
              responsive: true,
              maintainAspectRatio: false,
              legend: {
                display: false,
              },
              scales: {
                yAxes: [
                  {
                    stacked: true,
                    ticks: {
                      beginAtZero: true,
                      fontColor: "#FFFFFF",
                    },
                    gridLines: {
                      display: true,
                      color: "rgb(40,40,40)",
                    },
                  },
                ],
                xAxes: [
                  {
                    stacked: true,
                    ticks: {
                      fontColor: "#FFFFFF",
                    },
                    gridLines: {
                      display: true,
                      color: "rgb(40,40,40)",
                    },
                  },
                ],
              },
              tooltips: {
                enabled: true,
                mode: "index",
                intersect: false,
              },
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default DepartmentBarChart;
