import React, { useEffect } from "react";
import "date-fns";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import { styled } from "@mui/material/styles";
// Components
import { Typography, Divider, CircularProgress } from "@mui/material";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
// Icon
import DensitySmallIcon from '@mui/icons-material/DensitySmall';
import FastfoodIcon from "@mui/icons-material/Fastfood";
import FlightIcon from "@mui/icons-material/Flight";
import InfoIcon from "@mui/icons-material/Info";
import QueueMusicIcon from "@mui/icons-material/QueueMusic";
import LocalGroceryStoreIcon from "@mui/icons-material/LocalGroceryStore";
import PaletteIcon from "@mui/icons-material/Palette";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";
import SellIcon from '@mui/icons-material/Sell';
import CardTravelIcon from "@mui/icons-material/CardTravel";
import SearchIcon from "@mui/icons-material/Search";
import EventIcon from "@mui/icons-material/Event";
import PetsIcon from '@mui/icons-material/Pets';
import ChildFriendlyIcon from '@mui/icons-material/ChildFriendly';
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import CardRewardDetail from "./cardRewardDetail";
import { Link } from "react-router-dom";
import { useRewardType } from "../../../../../quries/reward";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const WrapRootSearch = styled(Paper)({
  padding: "2px 4px",
  display: "flex",
  alignItems: "center",
  width: "100%",
  marginBottom: 10,
})

const StyledInputSearch = styled(InputBase)(({ theme }) => ({
  marginLeft: theme.spacing(1),
  flex: 1,
}))

const StyledIconButtonSearch = styled(IconButton)({
  padding: 10,
})

const StyledCardMedia = styled(CardMedia)({
  height: 250,
  backgroundSize: "contain",
})

const WrapImageRewardItem = styled('div')({
  height: 100,
  width: 150,
  backgroundSize: "contain",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
})

const StyledCard= styled(Card)({
  width: "100%",
  padding: 15,
  borderRadius: 8,
})

const WrapRootCategory = styled('div')(({ theme }) => ({
  flexGrow: 1,
  width: "100%",
  backgroundColor: theme.palette.background.paper,
}))

const iconRewardType = {
  0: <DensitySmallIcon />,
  1: <FastfoodIcon />,
  2: <LocalGroceryStoreIcon />,
  3: <QueueMusicIcon />,
  4: <PaletteIcon />,
  5: <FlightIcon />,
  6: <LocalHospitalIcon />,
  7: <PetsIcon />,
  8: <ChildFriendlyIcon />,
}

export default function RewardCards({
  rewards,
  handleClickOpenEdit,
  // handleClickOpenDelete,
  handleClickOpenAddNewReward,
  valuesEdit,
  setValuesEdit,
}) {
  const [searchReward, setSearchReward] = React.useState("");
  const [idRewardType, setIdRewardType] = React.useState(0);
  const rewardType = useRewardType();

  const handleChange = (event, newValue) => {
    setIdRewardType(newValue);
  };

  const handleChangeSearchReward = (event) => {
    setSearchReward(event.target.value);
  };

  if (rewardType.isPending) {
    return <CircularProgress color="secondary" />;
  }

  return (
    <>
      <WrapRootSearch
        variant="outlined"
        component="form"
      >
        <StyledInputSearch
          value={searchReward}
          onChange={handleChangeSearchReward}
          placeholder="ค้นหาของรางวัล"
          inputProps={{ "aria-label": "search reward" }}
        />
        <StyledIconButtonSearch
          type="submit"
          aria-label="search"
          size="large">
          <SearchIcon />
        </StyledIconButtonSearch>
        <Divider
          style={{
            height: 28,
            margin: 4,
          }}
          orientation="vertical"
        />
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          component={Link}
          to={'/admin/manage/reward/company-reward/create'}
        >
          เพิ่มรางวัล
        </Button>
      </WrapRootSearch>

      <WrapRootCategory>
        <Paper position="static" color="default">
          <Tabs
            value={idRewardType}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
            style={{ marginBottom: 16 }}
          >
            {[{ idRewardType: 0, rewardType: "ทั้งหมด" },
              ...rewardType.data
            ].map((item) => (
              <Tab
                key={item.idRewardType}
                label={
                  <div style={{ display: "flex" }}>
                    {iconRewardType[item.idRewardType]}
                    <Typography style={{ marginTop: 4, marginLeft: 5 }}>
                      {item.rewardType}
                    </Typography>
                  </div>
                }
                {...a11yProps(item.idRewardType)}
              />
            ))}
          </Tabs>
        </Paper>
        {[{ idRewardType: 0, rewardType: "ALL" }, ...rewardType.data].map(
          (item, index) => (
            <TabPanel value={idRewardType} index={index} key={index}>
              <CardRewardDetail
                idRewardType={item.idRewardType}
                searchReward={searchReward}
              />
            </TabPanel>
          )
        )}
      </WrapRootCategory>
    </>
  );
}
