import React from "react";
import ListMenu from "../../../pages/shared/listMenu";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import SettingsIcon from '@mui/icons-material/Settings';
import HistoryIcon from '@mui/icons-material/History';

const UniTeamMenu = () => {
  const dataListMenu = [
    {
      listItemIcon: <ShoppingCartIcon />,
      listItemText: "ของรางวัล",
      listLink: "/uni-team/reward",
      listKey:"uni-reward",
    },
    {
      listItemIcon: <SettingsIcon />,
      listItemText: "จัดการของรางวัล",
      listLink: "/uni-team/reward/list",
      listKey:"uni-reward-list",
    },
    {
      listItemIcon: <HistoryIcon />,
      listItemText: "ประวัติการขาย",
      listLink: "/uni-team/reward/sale-history",
      listKey:"uni-reward-sale-history",
    },
  ];

  return (
    <ListMenu dataListMenu={dataListMenu} menuRole={"Uni team"} />
  );
};

export default UniTeamMenu;
