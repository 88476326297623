import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import UploadFile from "../../../shared/uploadFile/uploadFile";
import TextFieldTheme from "../../../shared/general/TextFieldTheme";
import * as yup from "yup";
import { styled } from "@mui/material/styles";

import {
    Grid,
    Typography,
    Button,
    Paper,
  } from "@mui/material";
import { useDispatch } from "react-redux";
import { getMissionByUserId } from "../../../../../actions/mission";
import { updateMission } from "../../../../../actions/mission";
const ButtonSubmit = styled(Button)({
    marginLeft: 16,
  });
  
const FormSubmitMission = (props)=>{
  const {setSnackBarConfig,setModeSnackbar,setDisplaySnackbar,idMission,handleClose} = props;

  const dispatch =  useDispatch();
  const validationSchema = yup.object().shape({
      detail: yup.string().max(200).required("กรุณากรอกรายละเอียด"),
      keyLearning: yup.string().max(200).required("กรุณากรอกรายละเอียด"),
      file: yup.mixed().nullable(),
    });
  
    const { control, handleSubmit ,formState:{errors}} = useForm({
      defaultValues: {
        detail: "",
        keyLearning: "",
        file: null,
      },
      resolver: yupResolver(validationSchema)
    })
  

    const onSubmit = async (values) => {
      values.idMissionStatus = 2;
      const formData = new FormData();
      for (const key in values) {
        formData.append(key, values[key]);
      }
    
      const result = await dispatch(updateMission(idMission, formData));
      dispatch(getMissionByUserId());
      handleClose();
      if (result && result.status === 201) {
        setSnackBarConfig(true);
        setDisplaySnackbar("ส่งภารกิจสำเร็จ");
        setModeSnackbar("success");
    } else {
        setSnackBarConfig(true);
        setDisplaySnackbar("เกิดข้อผิดพลาด กรุณาลองใหม่ภายหลัง");
        setModeSnackbar("error");
    }
  };
    
  


  return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={3} marginBottom={2}>
              <Grid item xs={12}>
              <Typography className="field-label" color="text.secondary" gutterBottom>
          รายละเอียด
          </Typography>
              <Controller
                    control={control}
                    name="detail"
                    rules={{
                        required: true,
                    }}
                    render={({field})=> (
                        <TextFieldTheme
                        {...field}
                        multiline
                        minRows={3}
                        placeholder={"รายละเอียด"}
                        helperText={errors.detail?.message}  
                        error={!!errors.detail}  

                        />
                    )}
                />
              </Grid>
              <Grid item xs={12}>
              <Typography className="field-label" color="text.secondary" gutterBottom>
              keyLearning
          </Typography>
              <Controller
                    control={control}
                    name="keyLearning"
                    rules={{
                        required: true,
                    }}
                    render={({field})=> (
                        <TextFieldTheme
                        {...field}
                        multiline
                        minRows={3}
                        placeholder={"keyLearning"}
                        helperText={errors.keyLearning?.message}  
                        error={!!errors.keyLearning}  

                        />
                    )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="file"
                  control={control}
                  render={({ field }) => (
                    <UploadFile
                      {...field}
                      acceptFileType={"image/jpeg, image/png, application/pdf"}
                      acceptFileTypeLabel={"*.jpeg and *.png images, *.pdf"}
                      name={"ไฟล์รายละเอียด"}
                      multiple={false}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button variant="contained" color="error" onClick={handleClose}>
                ยกเลิก
              </Button>
              <ButtonSubmit variant="contained" color="success" type="submit">
                ส่งภารกิจ
              </ButtonSubmit>
            </div>
        </form>
  )
}

export default FormSubmitMission;